import React, { useEffect, useMemo, useState } from "react";
import "../ProductListing/ProductListing.css";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import { useDispatch, useSelector } from "react-redux";
import { addInventoryProducts, addProducts, removeInventoryProduct, subtractInventoryProduct } from "../../redux/reducers/InventoryCartSlice";
import { debounce } from "../../utils/helperFunctin";
import { getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { FRANCHISE_CART, FRANCHISE_INVENTORY_CART, FRANCHISE_INVENTORY_ORDER, FRANCHISE_ORDER, FRANCHISE_USER_CART, FRANCHISE_OFFLINE_ORDER, HIBEE_CART_URL, HIBEE_ORDER_URL } from "../../api_interface/apiURLs";
import { toast } from "react-toastify";
import { Input } from "@mui/material";

const CartPage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { products, user } = useSelector(state => state?.inventoryCartReducer)
  const franchise = useSelector((state) => state?.userReducer);

  console.log(products,franchise,'khkh');
  const franchiseId = franchise?._id
  const [loader, setLoader] = useState(false)
  const [franchiseName, setFranchiseName] = useState()

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(products.length / itemsPerPage);
  const currentProducts = products
    .slice(itemOffset, endOffset)
    .filter((product) => product?.qty >= 1);
  const [manualQuantities, setManualQuantities] = useState({});

  const handleManualQuantityChange = (productId, newQuantity) => {
    setManualQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };




  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  const deliveryTime = 0

  const syncCart = useMemo(() => debounce(async (products) => {
    if (franchiseId && products?.length) {
      const data = products
        .filter((el) => el?.qty >= 1)
        .map((el) => ({
          productId: el?._id,
          qty: manualQuantities[el?._id] || el?.qty,
        }));
      const res = await putTokenApi(HIBEE_CART_URL + FRANCHISE_CART + FRANCHISE_INVENTORY_CART, { products: data })
    }
  }, 500), [manualQuantities])
  const getProductsSubTotal = () =>
    products.reduce((acc, obj) => {
      const quantity = manualQuantities[obj._id] || obj?.qty;
      return acc + Number(obj.price) * Number(quantity);
    }, 0);


  const getUserCartData = async () => {
    setLoader(true)
    const res = await getTokenApi(HIBEE_CART_URL + FRANCHISE_CART + `?userId=${franchiseId}`)
    console.log(res, "franchiseIdfranchiseIdfranchiseIdfranchiseIdfranchiseId")
    if (res?.data?.data) {
      const { userDetails, items } = res?.data?.data
      dispatch(addProducts({ products: items }))
      setFranchiseName(res?.data?.data.owner_name)

    }
    setLoader(false)
  }
  const checkoutOrder = async () => {
    setLoader(true)
    const data = {
      type: "inventory",
      paymentDetails: { "mode": "cash", "status": "pending" }
    }
    try {
      const res = await postTokenApi(HIBEE_ORDER_URL + FRANCHISE_ORDER + FRANCHISE_INVENTORY_ORDER, data)
      toast.success(res?.data?.message || "Order placed.", {
        position: "top-center",
        autoClose: 2000,
      });
      dispatch(addProducts({ products: [] }))
      setLoader(false)
      navigate("/orders")
    } catch (err) {
      setLoader(false)
      const errorMessage = err?.response?.data?.message || "Error placing order. Please try again later.";
      toast.error(errorMessage, { position: "top-center", autoClose: 3000 });
    }
  }

  useEffect(() => { syncCart(products) }, [products,manualQuantities])

  useEffect(() => { getUserCartData() }, [])

  return (
    <MainTemplate categoryName="Categories" categoryList={["Franchise", "Inventory Cart"]}>

      <div className="row mb-4 rounded px-3 bg-white">
        {/* header */}
        <div className="row m-0 ">
          <div className="font-weight-bold t-12 tFext-uppercase my-3 col-md-5">
            Product
          </div>
          <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
            Qty
          </div>
          <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
            Price
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
            Amount
          </div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
            Delete
          </div>
        </div>

        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
          {
            currentProducts?.length
              ?
              currentProducts?.map((el, index) => (
                <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center" key={el?._id} >
                  <div className="t-12 text-uppercase my-3 col-md-5">
                    <span className="categoryImageDiv shadow-sm rounded p-1">
                      {el?.images && el.images.length > 0 ? (
                        <img src={el.images[0].image} style={{ width: "42px", height: "45px", objectFit: "cover" }} alt="loading" className="cme-2 categoryImage rounded" />
                      ) : (
                        <img src={el?.image} style={{ width: "42px", height: "45px", objectFit: "cover" }} alt="loading" className="me-2 categoryImage rounded" />
                      )}
                    </span>
                    <span>{el?.product_name}</span>
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-3">
                    <span style={{ marginLeft: '-30px' }}>
                      <button
                        className="btn btn-light shadow-sm px-2 py-1"
                        onClick={() => {
                          const currentQty = manualQuantities[el?._id] || el?.qty;
                          const newQty = Math.max(currentQty - 1, 0); // Ensure quantity is not negative
                          handleManualQuantityChange(el?._id, newQty);
                          dispatch(subtractInventoryProduct({ ...el, qty: newQty }));
                        }}
                      >
                        -
                      </button>
                      <Input
                        type="text"
                        inputMode="numeric"
                        value={manualQuantities[el?._id] || el?.qty}
                        className="btn btn-light"
                        onChange={(e) => {
                          const newQty = Math.max(parseInt(e.target.value), 0); // Ensure quantity is not negative
                          handleManualQuantityChange(el?._id, newQty);
                        }}
                        style={{ width: '20%' }}
                      />
                      <button
                        className="btn btn-light shadow-sm px-2 py-1"
                        onClick={() => {
                          const currentQty = manualQuantities[el?._id] || el?.qty;
                          const newQty = currentQty + 1;
                          handleManualQuantityChange(el?._id, newQty);
                          dispatch(addInventoryProducts({ ...el, qty: newQty }));
                        }}
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-2">
                    ₹ {el?.price}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-1">
                    ₹ {el?.price * (manualQuantities[el?._id] || el?.qty)}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-1 d-flex justify-content-center" >
                    <svg onClick={() => dispatch(removeInventoryProduct(el))} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                  </div>
                  {/*<div className="t-12 text-uppercase my-3  row justify-content-end  col-md-4">
                   {
                    cartProducts?.find(ele => ele?._id === el?._id && ele?.qty > 0)
                    ?
                    <button className="addbtn text-end" onClick={() => dispatch(removeUserProduct(el))}>Remove</button>
                    :
                    <button className="addbtn text-end"onClick={() => dispatch(addUserProducts(el))}>Add</button>
                  } 
                  <button className="addbtn text-end" >Add</button>
                </div>*/}
                </div>
              ))
              :
              <div className="d-flex h-100 t-12 flex-column justify-content-center align-items-center text-uppercase ">
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-cart-x" viewBox="0 0 16 16">
                  <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z" />
                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                <i className="my-2">Your Cart Is Empty</i>
              </div>
          }
        </div>

        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>

      <div className="row mb-4 rounded p-2 py-3 bg-white axis">
        <div className="row rounded px-3 bg-white axis">
          <div class="row justify-content-center">

            <div class="col-6">
              {franchiseName}
            </div>

            <div class="col-6">
              <table className="table table-sm ">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>₹ {getProductsSubTotal()}</td>
                  </tr>
                  <tr className="sot">
                    <td>Delivery Charges</td>
                    <td>₹ {deliveryTime}</td>
                  </tr>
                  <tr className="sot">
                    <td>Total</td>
                    <td>₹ {getProductsSubTotal() + deliveryTime}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div class="col-sm-12 text-end">
          <Link to={"/inventory-product-listing"}>
            <button
              id="btnSearch"
              className="btn btn-outline-dark px-4 m-2"
            >
              Continue Shopping
            </button>
          </Link>

          <button
            id="btnClear"
            className="btn btn-dark  px-5 m-2 text-warning"
            onClick={checkoutOrder}
          >
            Checkout
          </button>
        </div>
      </div>
    </MainTemplate>
  );
};

export default CartPage;
