import React from "react";
import MainTemplate from "../../../components/MainTemplate";
import "./Revenue.css";
import img1 from "../../../assets/images/revenue/Group.png";
import img2 from "../../../assets/images/revenue/Vector.png";
import img3 from "../../../assets/images/revenue/Group (1).png";
import img4 from "../../../assets/images/revenue/img4.png"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import moment from "moment"
import { getTokenApi } from "../../../api_interface/apis";
import { CODLIMIT, DASHBOARD, FRANCHISE_ORDER, FRANCHISE_PROFILE, HIBEE_ORDER_URL, HIBEE_PRODUCT_URL, PRODUCT_LIST } from "../../../api_interface/apiURLs";
import { useSelector } from "react-redux";


function Revenue() {

  const [revenueData, setRevenueData] = useState({ loader: false, mainData: [], totalCommission: "" })
  const [orders, setOrders] = useState([]);
  const [query, setQuery] = useState("");
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [commissionData, setCommissionData] = useState([]);
  const [currentamount, setCurrentAmount] = useState([])
  const [filteredCommissionData, setFilteredCommissionData] = useState([]);
  const [filteredMainData, setFilteredMainData] = useState([]);
  const [exportOption, setExportOption] = useState(false)
  const [filteredList, setFilteredList] = useState([]);
  const [codamount, setCodAmount] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(0);


  const user = useSelector((state) => state.userReducer);




  const handleGetDashBoard = async () => {
    try {
      const response = await getTokenApi(HIBEE_ORDER_URL + DASHBOARD +
        `/?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}&export=${exportOption}&search=${query}`)
      if (response?.data) {

        const dashboard = response?.data?.orders
        const thismonthmoney = response?.data
        const currentamount = response?.data?.thisMonthsDeliveredAmount;
        const roundedAmount = Math.ceil(currentamount)
        setCurrentAmount(thismonthmoney)
        setFilteredList(dashboard)
      }
    }
    catch (err) {

      console.log(err);
    }
  }
  const handleGetRevenue = async () => {
    try {
      const response = await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + CODLIMIT)
      const responseData = response.data.data
      const currentamount = response?.data?.data;
      const COD = currentamount?.cod_status
      setCodAmount(responseData)
      console.log(currentamount, "statusstatusstatusstatusstatusstatus")
    }
    catch (error) {
      console.log(error);
    }

  };



  // dateSelector
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    console.log(event.selected + 1);

  };
  const handleStartDateChange = (date) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = date
      ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
      : "";
    setFormattedStartDate(formattedDate);
    setFormattedEndDate("");
  };

  const handleEndDateChange = (date) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = date
      ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
      : "";
    setFormattedEndDate(formattedDate);
  };
  const [filterStatus, setFilterStatus] = useState(false);

  const handleOkClick = () => {
    // do something with the selected values
    setFilterStatus(false);
  };
  async function handleExportClick() {
    // Assuming you have the API JSON data stored in a variable called `jsonData`

    // Set the export option to true
    await setExportOption(true);

    // Get the API JSON data
    await handleGetDashBoard();

    // Specify the headers you want to export
    const headersToExport = ['_id', 'username', 'createdAt', 'amount', 'status', 'ordertype'];

    // Convert JSON to CSV format with the specified headers
    const csvContent = convertToCSV(filteredList, headersToExport);

    // Create a temporary anchor element to initiate the download
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
    downloadLink.download = 'data.csv';

    // Trigger the download
    downloadLink.click();

    // Set the export option back to false
    await setExportOption(false);
  }
  function convertToCSV(jsonData, headersToExport) {
    // Filter the JSON data to include only the objects with the desired headers
    const filteredData = jsonData.map(obj => {
      const filteredObj = {};
      headersToExport.forEach(header => {
        filteredObj[header] = obj[header];
      });
      return filteredObj;
    });

    // Extract column headers from the first object in the filtered data
    const headers = Object.keys(filteredData[0]);

    // Convert each object to an array of values
    const rows = filteredData.map(obj => Object.values(obj));

    // Combine headers and rows
    const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');

    return csvContent;
  }


  // const totalcommission = revenueData.mainData.total_commission
  // const totalcomission =  totalcommission.reduce((accumulator , commission)=>{
  //     return accumulator + commission.total_commission 
  // })
  useEffect(() => {
    handleGetRevenue()
  }, [currentPage])
  useEffect(() => {
    handleGetDashBoard()
  }, [currentPage, formattedStartDate, formattedEndDate, exportOption, query],)


  return (
    <MainTemplate categoryName="Finance" categoryList={["Finance"]}>
      <div className="row mb-4 rounded p-3 bg-white">

        <div className="col-sm-6 my-3 col-md-4 ">
          <div className="d-flex mx-0 align-items-center ">
            <p className="d-flex align-items-center w-100 m-0 ">From Date</p>
            <DatePicker
              className="p-2 border border-2 rounded mx-0"
              value={formattedStartDate ? formattedStartDate : "YYYY-MM-DD"}
              onChange={handleStartDateChange}
              dateFormat={"YYYY-MM-DD"}
              maxDate={new Date()}
            />

          </div>
          {/* <select className="t-13 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select> */}
        </div>
        <div className="col-sm-6 my-3 col-md-4">
          <div className="d-flex mx-0 align-items-center ">
            <p className="d-flex align-items-center w-100 m-0 ">To Date</p>
            <DatePicker
              className="p-2 border border-2 rounded mx-0"
              value={formattedEndDate ? formattedEndDate : "YYYY-MM-DD"}
              onChange={handleEndDateChange}
              dateFormat={"YYYY-MM-DD"}
              disabled={formattedStartDate ? false : true}
              maxDate={new Date()}
            />

          </div>
          {/* <select
            className="t-13 form-select"
            aria-label="Default select example"
          >
            <option selected>Weekly</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> */}
        </div>
        <div className="col-sm-6 my-3 col-md-4 d-flex align-items-center m-0">
          <Link to="/cod-active" className="w-75">
            <button
              className="btn btn_bg t-13 w-75 p-2 rounded btn-dark text-warning ">
              Pay Superadmin
            </button>
          </Link>
        </div>
      </div>

      <div className="row mb-4 rounded p-2 pb-4 bg-white d-flex justify-content-evenly">
        <div className="col-3 m-1 mb-2 mt-4 rounded p-2 bg-white colbox">
          <div className="row m-1">
            <div className="col-2 m-2 offset-1 imgdef">
              <img src={img1} alt="img" />
              <div className="text-rev">Revenue</div>

              <div className="text2">{'₹'}{currentamount?.thisMonthsDeliveredAmount}</div>
              <div className="green">Over Last Month</div>
            </div>

            <div className="col-1 offset-1 mt-2 imgdef">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16" >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg> */}
              <div className="bottom">
              </div>
            </div>
          </div>
        </div>

        <div className="col-3 m-1 mb-2 mt-4 rounded p-2 bg-white colbox">
          <div className="row m-1">
            <div className="col-2 m-2 offset-1 imgdef">
              <img src={img3} alt="img" />
              <div className="text-rev" >Normal Delivery Commmission</div>

              <div className="text2">{'₹'}{currentamount?.thisMonthDeliveredCommission}</div>
              <div className="green">Over Last Month</div>
            </div>

            <div className="col-1 offset-1 mt-2 imgdef">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots dropdown-btn cursorPointer"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg> */}
              <div className="bottom">
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 m-1 mb-2 mt-4 rounded p-2 bg-white colbox">
          <div className="row m-1">
            <div className="col-2 m-2 offset-1 imgdef">
              <img src={img3} alt="img" />
              <div className="text-rev">SuperFast Commmission</div>

              <div className="text2">{'₹'}{currentamount?.thisMonthDeliveredCommission}</div>
              <div className="green">Over Last Month</div>
            </div>

            <div className="col-1 offset-1 mt-2 imgdef">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots dropdown-btn cursorPointer"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg> */}
              <div className="bottom">
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 m-1 mb-2 mt-4 rounded p-2 bg-white colbox">
          <div className="row m-1">
            <div className="col-2 m-2 offset-1 imgdef">
              <img src={img4} alt="img" />
              <div className="text-rev pt-2">Liquid cash</div>

              <div className="text2 mt-3">{'₹'}{codamount?.cod_amount}</div>

              <div className="red" style={{ display: 'flex', flexDirection: 'column' }} >
                <div className=""><span>Current Balance : </span>{' '} {'₹'} {' '}{codamount?.currentBalance}</div>


              </div>

            </div>

            <div className="col-1 offset-1  imgdef">
              {/* <Link to="/cod-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots dropdown-btn cursorPointer"
                viewBox="0 0 16 16"
                >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
                </Link> */}
              <div className="bottom">
              </div>
            </div>
          </div>
        </div>
       


      </div>
      <div className="row mb-4 rounded p-3 bg-white">

        <div className="col-sm-6 my-3 col-md-4 ">
          <div className="d-flex mx-0 align-items-center ">


          </div>

        </div>
        <div className="col-sm-6 my-3 col-md-4 ">
          <div className="d-flex mx-0 align-items-center ">
            <input
              type="text"
              name="query"
              className="t-13 form-control"
              placeholder="Search Name or ID"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>

        </div>
        <div className="col-sm-6 my-3 col-md-4 d-flex align-items-center m-0 d-flex justify-content-center">
          <button
            className="t-13 ms-2 btn_bg px-4 btn btn-dark text-warning"
            onClick={handleExportClick}
          >
            Export
          </button>
        </div>
      </div>

      <div className="row mb-4 rounded px-3 bg-white">
        {/* header */}
        <div className="row m-0  List_mobile_title">
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-1 d-flex justify-content-center">
            Date
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-1 d-flex justify-content-center">
            Order Id
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-2 d-flex justify-content-center">
            Customer Name
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-2 d-flex justify-content-center">
            Commmission
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-2 d-flex justify-content-center">
            SuperFast
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-1 d-flex justify-content-center">
            Payment
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-2 d-flex justify-content-center">
            Status
          </div>
          <div className="font-weight-bold t-13 text-uppercase my-3 col-md-1 d-flex justify-content-center">
            Revenue
          </div>

        </div>

        <div className="m-0 p-0 px-3 overflow-auto caterogyPageScroll">

          {
            filteredList?.map(el => {


              return <div className="row border rounded mb-2  ">
                <div className="t-13 my-3 col-md-1 d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display">Date</span>
                  {moment(el?.createdAt).format('DD/MM/YYYY')}
                </div>
                <div className="t-13 my-3 col-md-1 overflow-hidden d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display" >Revenue</span>
                  <Link to={`/order-details/${el?._id}`}> {el?._id.slice(0, 5)}</Link>
                </div>
                <div className="t-13 my-3 col-md-2 overflow-hidden d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display" >Customer Name</span>
                  {el?.type === "inventory" ? el?.franchise_owner_name : el?.userDetails?.username}
                </div>
                <div className="t-14 my-3 col-md-2 overflow-hidden d-flex justify-content-center">
                  {el?.total_commission !== undefined ? (
                    <span>{el?.total_commission}</span>
                  ) : (
                    <span>No Commission</span>
                  )}
                </div>
                <div className="t-13 my-3 col-md-2 overflow-hidden d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display" ></span>
                  {Boolean(el?.superfast) ? "Yes" : "No"}
                </div>
                <div className="t-13 my-3 col-md-1 d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display ">Commmission</span>
                  Cash

                </div>
                <div className="t-13 my-3 col-md-2 d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display">Commmission</span>
                  <div
                    className=" text-white p-1 rounded"
                    style={{
                      backgroundColor: `${(el.status == "processing" && "#FEBF22") ||
                        (el.status == "cancelled" && "#E93737") ||
                        (el.status == "returned" && "#000000") ||
                        (el.status == "delivered" && "#29AE13")
                        }`,
                      width: "80px",
                    }}
                  >
                    {el?.status}
                  </div>


                </div>
                <div className="t-13 my-3 col-md-1 d-flex d-flex justify-content-center">
                  <span className="fw-bold List_mobile_display">Commmission</span>
                  {"₹"} {el?.amount}

                </div>
                {/* 
              <div className="t-13 d-flex justify-content-center  p-0 my-3 col-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16" >
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                </svg>
              </div> */}
              </div>
            })
          }

        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCounts}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </MainTemplate>
  );
}

export default Revenue;
