import React, { useEffect, useRef, useState } from "react";
import "./Orders.css";
import { Link, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactModal from "react-modal";
import MainTemplate from "../../components/MainTemplate";
import { getTokenApi } from "../../api_interface/apis";
import { FRANCHISE_ORDER, HIBEE_ORDER_URL } from "../../api_interface/apiURLs";
import { customModalStyles } from "../../utils/helperFunctin";
import invoicelogo from "../../assets/images/invoceLogo.png"
import ReactPrint from 'react-to-print';
import { BiEditAlt } from "react-icons/bi"
import { RiDeleteBin6Line } from "react-icons/ri"
function OrderDetails() {
  const [orderDetails, setOrderDetails] = useState([]);
  const [modal, setModal] = useState({ data: [], loader: false, show: false });
  const [updatedQty, setUpdatedQty] = useState({ data: [], loader: false, show: false });

  const { orderId } = useParams();
  const [loader, setLoader] = useState(false);

  const handleGetOrderDetails = async () => {
    setLoader(true);

    const responce = await getTokenApi(
      HIBEE_ORDER_URL + FRANCHISE_ORDER + `/${orderId}`
    );
    if (responce.status == 200) {
      setOrderDetails([responce.data.data]);
      setLoader(false);
    } else {
      console.log("failure");
    }
  };

  // let pdf = null; // Declare pdf variable outside the createPDF function

  // const createPDF = async () => {
  //   console.log("I am creating PDF");

  //   // Reset the pdf variable
  //   pdf = new jsPDF("portrait", "pt");

  //   const element = document.querySelector("#order_detail");
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   // Helper function to add page and move to the next page if necessary
  //   const addPageIfNeeded = () => {
  //     const currentHeight = pdf.internal.getNumberOfPages() * pdfHeight;
  //     if (currentHeight >= element.offsetHeight) {
  //       pdf.addPage();
  //     }
  //   };

  //   // Helper function to capture screenshot and add to PDF
  //   const captureScreenshotAndAddToPDF = async () => {
  //     const canvas = await html2canvas(element);
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   };

  //   // Capture initial screenshot and add to PDF
  //   await captureScreenshotAndAddToPDF();
  //   addPageIfNeeded();

  //   // Scroll through the content and capture screenshots for each section
  //   const scrollHeight = element.scrollHeight;
  //   const clientHeight = element.clientHeight;
  //   let scrollTop = 0;
  //   while (scrollTop + clientHeight < scrollHeight) {
  //     element.scrollTop += clientHeight; // Scroll to next section
  //     await new Promise((resolve) => setTimeout(resolve, 100)); // Delay for rendering
  //     await captureScreenshotAndAddToPDF();
  //     addPageIfNeeded();
  //     scrollTop = element.scrollTop;
  //   }
  // };

  // const resetPDF = () => {
  //   pdf = null;
  // };

  // const downloadPDF = () => {
  //   if (pdf) {
  //     pdf.save("shipping_label.pdf");
  //     resetPDF();
  //   } else {
  //     console.log("PDF is not created yet.");
  //   }
  // };


  useEffect(() => {
    if (orderId) {
      handleGetOrderDetails();
    }
  }, [orderId]);
  console.log(orderDetails);
  const total = orderDetails[0]?.products?.reduce(
    (acc, el) => acc + el.price * el.qty,
    0
  );


  // const MyPDF = () => (
  //   <Document>
  //     <Page>
  //       <View>
  //         <View style={styles.container}>
  //           <View>
  //             <Text style={styles.companyName}>Company Name: <Text style={styles.companyId}>Moppets pvt. ltd.</Text></Text>
  //             <Text style={styles.companyName}>CIN: <Text style={styles.companyId}>SNK2849DE</Text></Text>
  //             <Text style={styles.companyInfo}>
  //               +91 9901472837 | Admin@Hibee.in  #44, 6th cross, Banshankari layout, Bangalore - 01
  //             </Text>
  //           </View>
  //           <View>
  //             <Image src={invoicelogo} alt="" style={styles.logo} />
  //           </View>
  //         </View>
  //         {/* Add other content here */}
  //       </View>
  //     </Page>
  //   </Document>
  // );

  // Font.register({
  //   family: 'DM Sans',
  //   fonts: [
  //     { src: DMRegular, fontWeight: 'normal', format: 'truetype' }
  //   ]
  // });

  // const styles = StyleSheet.create({
  //   container: {

  //   },
  //   companyName: {
  //     margin: 0,
  //     padding: 0,
  //     fontFamily: 'DM Sans',
  //     fontStyle: 'normal',
  //     fontWeight: 700,
  //     fontSize: 12,
  //     lineHeight: 16,
  //     color: '#000000',
  //   },
  //   companyId: {
  //     fontFamily: 'DM Sans',
  //     fontStyle: 'normal',
  //     fontWeight: 400,
  //     fontSize: 12,
  //     lineHeight: 16,
  //     color: '#000000',
  //   },
  //   companyInfo: {
  //     fontFamily: 'DM Sans',
  //     fontStyle: 'normal',
  //     fontWeight: 400,
  //     fontSize: 12,
  //     lineHeight: 16,
  //     color: '#000000',
  //   },
  //   logo: {
  //     width: 149.16,
  //     height: 61.24,
  //   },
  // });

  const ref = useRef()

  const [selectedInventory, setSelectedInventory] = useState(null);

  const handleInventoryClick = (inventory) => {
    setSelectedInventory(inventory);
    setQty(inventory.qty)
  };
  console.log(selectedInventory, "selected");

  const [qty, setQty] = useState();

  const incrementQty = () => {
    setQty(prevQty => prevQty + 1);
  };

  const decrementQty = () => {
    if (qty > 0) {
      setQty(prevQty => prevQty - 1);
    }
  };

  const [download, setDownload] = useState(false)


  const handleButtonClick = () => {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  console.log(download, "qa");
  return (
    <MainTemplate
      categoryName="Order #12K4"
      categoryList={["Admin", "Orders", "#12K4"]}
    >
      {loader ? (
        <div className="text-center mt-5">
          <button
            class="btn mt-5"
            style={{ backgroundColor: "#FFEDC2" }}
            type="button"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{" "}
            Loading...
          </button>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-8 ps-0 h-100">
              <div className="p-4 bg-white rounded">
                <div>
                  <div className="d-flex justify-content-between flex-wrap">
                    <h4> {orderDetails &&
                          orderDetails[0] &&
                          orderDetails[0].type === "inventory"
                          ? orderDetails[0]?.franchise_owner_name
                          : orderDetails[0]?.userDetails?.username}</h4>
                    <p>
                      <span
                        class="text-white t-12 deliveredSuccess text-capitalize"
                        style={{
                          backgroundColor: `${(orderDetails[0]?.status == "processing" &&
                            "#FEBF22") ||
                            (orderDetails[0]?.status == "dispatched" &&
                              "#E96511") ||
                            (orderDetails[0]?.status == "franchise_recieved" &&
                              "#65c") ||
                            (orderDetails[0]?.status == "cancelled" &&
                              "#E93737") ||
                            (orderDetails[0]?.status == "returned" &&
                              "#000000") ||
                            (orderDetails[0]?.status == "delivered" &&
                              "#29AE13")
                            }`,
                        }}
                      >
                        {orderDetails && orderDetails[0]?.status}
                      </span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div>
                      <p className="orderdetails-header">Order Created at</p>
                      <p className="orderdetails-info ">
                        {orderDetails &&
                          orderDetails[0]?.createdAt?.slice(0, 10)}
                      </p>
                    </div>
                    <div>
                      <p className="orderdetails-header">Name</p>
                      <p className="orderdetails-info " style={{ textTransform: "capitalize" }}>
                        {orderDetails &&
                          orderDetails[0] &&
                          orderDetails[0].type === "inventory"
                          ? orderDetails[0]?.franchise_owner_name
                          : orderDetails[0]?.userDetails?.username}
                      </p>
                    </div>
                    <div>
                      <p className="orderdetails-header" style={{ textTransform: "capitalize" }}>
                        {orderDetails &&
                          orderDetails[0] &&
                          orderDetails[0].type === "inventory"
                          ? "Email"
                          : "Flat.No"}
                      </p>
                      <p className="orderdetails-info " style={{ textTransform: "capitalize" }}>{orderDetails &&
                        orderDetails[0] &&
                        orderDetails[0].type === "inventory"
                        ? orderDetails[0]?.franchiseDetails?.email
                        : orderDetails[0]?.userDetails?.username}</p>
                    </div>
                    <div>
                      <p className="orderdetails-header">Contact No</p>
                      <p className="orderdetails-info ">
                        {orderDetails &&
                          orderDetails[0] &&
                          orderDetails[0].type === "inventory"
                          ? orderDetails[0]?.franchiseDetails?.phone_number
                          : orderDetails[0]?.userDetails?.phone}
                      </p>
                    </div>
                    <div>
                      <p className="orderdetails-header">Mode</p>
                      <p className="orderdetails-info  " style={{ textTransform: "capitalize" }}>
                        {orderDetails && orderDetails[0]?.type == "offline_user"
                          ? "offline"
                          : "online"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row t-14 mb-5">
                  <div className="font-weight-bold">Complete Address</div>
                  <div style={{ textTransform: "capitalize" }}> {orderDetails &&
                    orderDetails[0] &&
                    orderDetails[0].type === "inventory"
                    ? "Flat no:" : 'Flat no:'} {orderDetails &&
                      orderDetails[0] &&
                      orderDetails[0].type === "inventory"
                      ? orderDetails[0]?.franchiseDetails?.flatNumber
                      : orderDetails[0]?.userDetails?.flat}</div>
                  <div style={{ textTransform: "capitalize" }}> {orderDetails &&
                    orderDetails[0] &&
                    orderDetails[0].type === "inventory"
                    ? "Floor no:" : 'Floor no:'} {orderDetails &&
                      orderDetails[0] &&
                      orderDetails[0].type === "inventory"
                      ? orderDetails[0]?.franchiseDetails?.floor?.floorName
                      : orderDetails[0]?.userDetails?.floor}</div>
                  <div style={{ textTransform: "capitalize" }}> {orderDetails &&
                    orderDetails[0] &&
                    orderDetails[0].type === "inventory"
                    ? "" : 'Tower no:'} {orderDetails &&
                      orderDetails[0] &&
                      orderDetails[0].type === "inventory"
                      ? ""
                      : orderDetails[0]?.userDetails?.tower}</div>
                  <div style={{ textTransform: "capitalize" }}>{orderDetails &&
                    orderDetails[0] && orderDetails[0]?.societyDeatils?.societyName}</div>
                  <div style={{ textTransform: "capitalize" }}>{orderDetails &&
                    orderDetails[0] && orderDetails[0]?.societyDeatils?.societyAddressLine2}</div>
                  <div style={{ textTransform: "capitalize" }}>{orderDetails &&
                    orderDetails[0] && orderDetails[0]?.societyDeatils?.societyCity}</div>
                  <div style={{ textTransform: "capitalize" }}>Pincode:{orderDetails &&
                    orderDetails[0] && orderDetails[0]?.societyDeatils?.societyPincode}</div>
                </div>
              </div>
            </div>
            <div className="col-4 ps-0 h-100">
              <div className="p-4 mb-3 bg-white rounded">
                <div className="font-weight-bold mb-2">Price</div>
                <div className="d-flex justify-content-between t-14">
                  <span>Sub Total:</span>
                  <span>₹ {orderDetails && orderDetails[0]?.subTotal}</span>
                </div>
                <div className="d-flex justify-content-between t-14">
                  <span>Shipping:</span>
                  <span>Free</span>
                </div>
                <div className="d-flex justify-content-between t-14 mb-3">
                  <span>Tax({orderDetails && orderDetails[0]?.totalGstPercentage}%):</span>
                  <span>₹ {orderDetails && orderDetails[0]?.totalGstAmount}</span>
                </div>
                <div className="d-flex justify-content-between t-14 font-weight-bold">
                  <span>Total:</span>
                  <span>₹ {orderDetails && orderDetails[0]?.amount}</span>
                </div>
              </div>
              <div className="p-4 mb-3 bg-white rounded">
                <div className="font-weight-bold mb-2">Invoice</div>
                <div className="d-flex justify-content-between t-14">
                  <span>Invoice Date</span>
                  <span>
                    {orderDetails && orderDetails[0]?.createdAt?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex justify-content-between t-14">
                  <span>Invoice No :</span>
                  <span>#{orderDetails && orderDetails[0]?.invoicenumber}</span>
                </div>
                <div className="d-flex justify-content-between t-14 mb-3">
                  <span>GST Number :</span>
                  <span>12HY87072641Z0</span>
                </div>
                <div
                  className="d-flex justify-content-between t-14 "
                  onClick={() => orderDetails[0].status === "delivered" ? setModal({ show: true }) : ""}
                >
                  <span style={{ cursor: "pointer" }}>
                    <u >Download PDF</u>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="font-weight-bold row mb-3 ps-3">Order Items</div>
          <div className="row rounded px-3 bg-white">
            <div className="row m-0 ">
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
                Photo
              </div>
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
                Name
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Qty
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Price
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Tax(0%)
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Total
              </div>
            </div>

            <div className="m-0 p-0 pe-3">
              {orderDetails[0]?.products?.map((el) => {
                return (
                  <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center">
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      <div className="categoryImageDiv shadow-sm  rounded p-1">
                        <img src={el?.image} className="categoryImage h-100" />
                      </div>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-3">
                      {el?.product_name}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el.qty} (KG)
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      RS {el.price}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">₹ 0</div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      RS {el.qty * el.price}
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
          <div className="pt-2">
            <p className="invoiceOrderHeading">
              Order Items
            </p>
            <div className="mt-2 px-4 py-3 d-flex justify-content-center bg-white rounded">
              <table className="   w-100">
                <thead className="">
                  <tr className="">
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">HSN</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Name</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Qty</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Rate</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Discount</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Net amount</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Tax </p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Taxable amount</p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0">Total</p></div></th>
                    {/* <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0"></p></div></th>
                    <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                      <p className="m-0"></p></div></th> */}
                  </tr>
                </thead>
                <tbody className="">
                  {orderDetails[0]?.products?.map((el, index) => {
                    return (
                      <tr key={index} className=" ">
                        <td className="orderstabledetails  "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', borderRadius: "5px 0px 0px 5px", borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">{index + 1}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">{el.product_name}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">{el.qty}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">₹ {el.price}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">{el.discountPercentage}%</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">₹ {el.total}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">{el.sgstpercentage * 2}%</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">₹ {el.taxable_amount}</p></div></td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black', borderRadius: "0px 5px 5px 0px", borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0">₹ {el.discountedPrice}</p></div></td>
                        {/* <td className="orderstabledetails">
                          <div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderColor: "#E0E0E0" }}>
                            <p className="p-1 m-0"><BiEditAlt style={{ cursor: "pointer" }} onClick={() => {
                              setUpdatedQty({ show: true });
                              handleInventoryClick(el);
                            }} /></p>
                          </div>
                        </td>
                        <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" style={{ borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black', borderRadius: "0px 5px 5px 0px", borderColor: "#E0E0E0" }}>
                          <p className="p-1 m-0"><RiDeleteBin6Line className="text-danger" style={{ cursor: "pointer" }} /></p></div></td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      )}

      <ReactModal
        isOpen={updatedQty?.show}
        onRequestClose={() => setUpdatedQty({})}
        style={{
          ...customModalStyles,
          content: { ...customModalStyles?.content, width: "320px", height: "335px" },
        }}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >

        {selectedInventory ? (<div className="w-100">
          <div className="updateQtyHeading d-flex justify-content-end w-100" onClick={() => setUpdatedQty({ show: false })} style={{ cursor: "pointer" }}>
            x
          </div>
          <div className="updateQtyHeading">
            Edit Item to order
          </div>
          <div className="d-flex justify-content-center gap-4 mt-4">
            <div className="updatedqtyimg">
              <img src={selectedInventory?.image[0]} alt="" />
            </div>
            <div>
              <p className="updatedqtyproductname">
                {selectedInventory?.product_name}
              </p>
              <p className="">
                HSN: {selectedInventory?.hsn_code}
              </p>
              <div className="d-flex align-items-baseline gap-2">
                <p>QTY:</p>
                <button type="button" className="btn btn-outline-dark" onClick={incrementQty}>+</button>
                <p>{qty}</p>
                <button type="button" className="btn btn-outline-dark" onClick={decrementQty}>-</button>
              </div>
              <div className="d-flex align-items-center gap-2">
                <p className="updatedqtyproductname">
                  Total :
                </p>
                <p className="updatedtytotal">₹ {selectedInventory.price * qty}</p>
              </div>
              <button
                type="button"
                class="bulkuploadbutton1 actives"
              >
                Submit
              </button>
            </div>
          </div>
        </div>) : ""}

      </ReactModal>

      <ReactModal
        isOpen={modal?.show}
        onRequestClose={() => setModal({})}
        style={{
          ...customModalStyles,
          content: { ...customModalStyles?.content, width: "650px", height: "100vh" },
        }}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className=" d-flex justify-content-center">
          <ReactPrint trigger={() =>
            <button className="btn btn-dark text-warning" onClick={handleButtonClick}>
              Download Invoice
            </button>} content={() => ref.current} > </ReactPrint>
        </div>
        {loader ? (
          <div className="text-center mt-5">
            <button
              class="btn mt-5"
              style={{ backgroundColor: "#FFEDC2" }}
              type="button"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Loading...
            </button>
          </div>
        ) : (
          <>


            <div className="overflow-auto invoicePageScroll px-4 py-5">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="invoiceCompanyName">Company Name: <span className="invoiceComppanyid">Moppets pvt. ltd. </span></p>
                  <p className="invoiceCompanyName">CIN:  <span className="invoiceComppanyid">SNK2849DE </span></p>
                  <p className="invoiceComppanyid w-50 pt-2">
                    +91 9901472837 |  Admin@Hibee.in
                    #44,  6th cross, Banshankari layout, Bangalore - 01
                  </p>
                </div>
                <div>
                  <img src={invoicelogo} alt="" className="invoicelogo" />

                </div>
              </div>
              <div className="pt-2">
                <p className="invoiceCompanyName pt-1">FSSAI:  <span className="invoiceComppanyid"> </span></p>
                <p className="invoiceCompanyName pt-1">GST No. :  <span className="invoiceComppanyid"> </span></p>
              </div>
              <hr />
              <div>
                <div className="d-flex justify-content-center">
                  <p className="invoiceCompanyName">
                    Tax Invoice
                  </p>
                </div>
                <div className="border mt-3">
                  <div className="d-flex justify-content-between px-2 align-items-center">
                    <div className="p-2">
                      <p className="invoiceCompanyName pt-1">Invoice no. :  <span className="invoiceComppanyid"> #{orderDetails && orderDetails[0]?.invoicenumber}</span></p>
                      <p className="invoiceCompanyName pt-1">Invoice Date : <span className="invoiceComppanyid"> {orderDetails && orderDetails[0]?.updatedAt.slice(0, 10)} </span></p>
                      <p className="invoiceCompanyName pt-1">Order Number :  <span className="invoiceComppanyid">#895525 </span></p>
                    </div>
                    <div className="p-2">
                      <p className="invoiceCompanyName pt-1">Place of Supply : <span className="invoiceComppanyid">Bangalore, Karnataka (29)  </span></p>
                      <p className="invoiceCompanyName pt-1">Purchaser GST : <span className="invoiceComppanyid">ISB77382HG </span></p>
                      <p className="invoiceCompanyName pt-1">Order Date : <span className="invoiceComppanyid">{orderDetails && orderDetails[0]?.createdAt.slice(0, 10)} </span></p>
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex justify-content-between px-2">
                    <div className="p-2 w-50 ">
                      <p className="invoiceCompanyName pt-1">Shipping Address :</p>
                      <p className="invoiceComppanyid pt-1 m-0 ">
                        Rahul prasad
                        flat 5243, bllock C,
                        Prestige Layout, Shankarpura, Bangalore - 560001
                      </p>

                    </div>
                    <div className="p-2 w-50 " style={{ marginLeft: 25 }}>
                      <p className="invoiceCompanyName pt-1">Billing Address :</p>
                      <p className="invoiceComppanyid pt-1 m-0 ">
                        Flat 5243, BlockC,
                        Prestige Layout,
                        Shankarpura,
                        Bangalore - 560001
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <p className="invoiceOrderHeading">
                  Order Items
                </p>
                <div className="mt-2 d-flex justify-content-center">
                  <table className="border w-100">
                    <thead className="border">
                      <tr className="border">
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">S.No</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1 ">
                          <p className="p-0">Item Name & DEscription</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">HSN</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">QTY</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Rate (RS)</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Disc %</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Taxable AMT</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">SCGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">SGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Cess%</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CESS</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Total</p></div></th>

                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails[0]?.products?.map((el, index) => {
                        const displayIndex = index + 1;
                        return (
                          <tr key={index}>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{displayIndex}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.product_name}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">878465</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.qty}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.price}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.discountPercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.taxable_amount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cgstpercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.sgstpercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cgstamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.sgstamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cesspercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cessamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.discountedPrice}</p></div></td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <p className="invoiceCompanyName pt-1">GST Payable on Reverse Charge:<span className="invoiceComppanyid"> No </span></p>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Sub Total :</p>
                  <p className="invoiceComppanyid pt-1">₹{orderDetails && orderDetails[0]?.subTotal}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Handling Charges ( incl. of GST) :</p>
                  <p className="invoiceComppanyid pt-1">Free</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Tax ({orderDetails && orderDetails[0]?.totalGstPercentage}%) :</p>
                  <p className="invoiceComppanyid pt-1">₹{orderDetails && orderDetails[0]?.totalGstAmount}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Voucher Applied (FREEDEL)</p>
                  <p className="invoiceComppanyid pt-1">₹10</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Total :</p>
                  <p className="invoiceCompanyName pt-1">₹{orderDetails && orderDetails[0]?.amount}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Total Savings :</p>
                  <p className="invoiceCompanyName pt-1">₹{orderDetails && orderDetails[0]?.totalSavings}</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Payment Mode:</p>
                  <p className="invoiceComppanyid pt-1" style={{ textTransform: "capitalize" }}>{orderDetails && orderDetails[0]?.payment?.mode}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                <div>
                  <p className="invoicebottominfo d-flex justify-content-center">
                    Subjected to Bangalore Jurisdiction
                  </p>
                  <p className="invoicebottominfo d-flex justify-content-center">
                    This is a computer generated invoice and does not need a signature
                  </p>
                </div>

              </div>



            </div>
            <div style={{ display: "hidden" }} className="px-4 py-5" id="order_detail" ref={ref}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="invoiceCompanyName">Company Name: <span className="invoiceComppanyid">Moppets pvt. ltd. </span></p>
                  <p className="invoiceCompanyName">CIN:  <span className="invoiceComppanyid">SNK2849DE </span></p>
                  <p className="invoiceComppanyid w-50 pt-2">
                    +91 9901472837 |  Admin@Hibee.in
                    #44,  6th cross, Banshankari layout, Bangalore - 01
                  </p>
                </div>
                <div>
                  <img src={invoicelogo} alt="" className="invoicelogo" />

                </div>
              </div>
              <div className="pt-2">
                <p className="invoiceCompanyName pt-1">FSSAI:  <span className="invoiceComppanyid"> </span></p>
                <p className="invoiceCompanyName pt-1">GST No. :  <span className="invoiceComppanyid"> </span></p>
              </div>
              <hr />
              <div>
                <div className="d-flex justify-content-center">
                  <p className="invoiceCompanyName">
                    Tax Invoice
                  </p>
                </div>
                <div className="border mt-3">
                  <div className="d-flex justify-content-between px-2 align-items-center">
                    <div className="p-2">
                      <p className="invoiceCompanyName pt-1">Invoice no. :  <span className="invoiceComppanyid"> #{orderDetails && orderDetails[0]?.invoicenumber}</span></p>
                      <p className="invoiceCompanyName pt-1">Invoice Date : <span className="invoiceComppanyid"> {orderDetails && orderDetails[0]?.updatedAt.slice(0, 10)} </span></p>
                      <p className="invoiceCompanyName pt-1">Order Number :  <span className="invoiceComppanyid">#895525 </span></p>
                    </div>
                    <div className="p-2">
                      <p className="invoiceCompanyName pt-1">Place of Supply : <span className="invoiceComppanyid">Bangalore, Karnataka (29)  </span></p>
                      <p className="invoiceCompanyName pt-1">Purchaser GST : <span className="invoiceComppanyid">ISB77382HG </span></p>
                      <p className="invoiceCompanyName pt-1">Order Date : <span className="invoiceComppanyid">{orderDetails && orderDetails[0]?.createdAt.slice(0, 10)} </span></p>
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex justify-content-between px-2">
                    <div className="p-2 w-50 ">
                      <p className="invoiceCompanyName pt-1">Shipping Address :</p>
                      <p className="invoiceComppanyid pt-1 m-0 ">
                        Rahul prasad
                        flat 5243, bllock C,
                        Prestige Layout, Shankarpura, Bangalore - 560001
                      </p>

                    </div>
                    <div className="p-2 w-50 " style={{ marginLeft: 25 }}>
                      <p className="invoiceCompanyName pt-1">Billing Address :</p>
                      <p className="invoiceComppanyid pt-1 m-0 ">
                        Flat 5243, BlockC,
                        Prestige Layout,
                        Shankarpura,
                        Bangalore - 560001
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <p className="invoiceOrderHeading">
                  Order Items
                </p>
                <div className="mt-2 d-flex justify-content-center">
                  <table className="border w-100">
                    <thead className="border">
                      <tr className="border">
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">S.No</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1 ">
                          <p className="p-0">Item Name & DEscription</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">HSN</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">QTY</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Rate (RS)</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Disc %</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Taxable AMT</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">SCGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">SGST</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Cess%</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">CESS</p></div></th>
                        <th className="invoicetableheading border"><div className="d-flex justify-content-center align-items-center p-1">
                          <p className="p-0">Total</p></div></th>

                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails[0]?.products?.map((el, index) => {
                        const displayIndex = index + 1;
                        return (
                          <tr key={index}>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{displayIndex}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.product_name}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">878465</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.qty}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.price}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.discountPercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.taxable_amount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cgstpercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.sgstpercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cgstamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.sgstamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cesspercentage}%</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.cessamount}</p></div></td>
                            <td className="invoicetabledetails border"><div className="d-flex justify-content-center align-items-center p-1 py-2">
                              <p className="p-0">{el.discountedPrice}</p></div></td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <p className="invoiceCompanyName pt-1">GST Payable on Reverse Charge:<span className="invoiceComppanyid"> No </span></p>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Sub Total :</p>
                  <p className="invoiceComppanyid pt-1">₹{orderDetails && orderDetails[0]?.subTotal}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Handling Charges ( incl. of GST) :</p>
                  <p className="invoiceComppanyid pt-1">Free</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Tax ({orderDetails && orderDetails[0]?.totalGstPercentage}%) :</p>
                  <p className="invoiceComppanyid pt-1">₹{orderDetails && orderDetails[0]?.totalGstAmount}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceComppanyid pt-1">Voucher Applied (FREEDEL)</p>
                  <p className="invoiceComppanyid pt-1">₹10</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Total :</p>
                  <p className="invoiceCompanyName pt-1">₹{orderDetails && orderDetails[0]?.amount}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Total Savings :</p>
                  <p className="invoiceCompanyName pt-1">₹{orderDetails && orderDetails[0]?.totalSavings}</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="invoiceCompanyName pt-1">Payment Mode:</p>
                  <p className="invoiceComppanyid pt-1" style={{ textTransform: "capitalize" }}>{orderDetails && orderDetails[0]?.payment?.mode}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                <div>
                  <p className="invoicebottominfo d-flex justify-content-center">
                    Subjected to Bangalore Jurisdiction
                  </p>
                  <p className="invoicebottominfo d-flex justify-content-center">
                    This is a computer generated invoice and does not need a signature
                  </p>
                </div>

              </div>



            </div>
          </>
        )}

      </ReactModal>
    </MainTemplate>
  );
}




export default OrderDetails;