import React, { useState } from "react";

import "./DataOfUsers.css";
import MainTemplate from "../../../src/components/MainTemplate";
import Green from "../../../src/assets/images/image 15.png";
import Bread from "../../../src/assets/images/toppng 13.png";
import Cleaner from "../../../src/assets/images/toppng 14.png";
import Dairy from "../../../src/assets/images/CategoryPage/dairy and eggs.png";
import Sweets from "../../../src/assets/images/CategoryPage/sweets.png";
import ReactPaginate from "react-paginate";
import Dots from "../../../src/assets/images/Group 1 (1).png";
import Vegs from "../../../src/assets/images/toppng 1 (1).png";
import Third from "../../../src/assets/images/toppng 15.png";
import SearchBox from "../../assets/images/Rectangle 10.png";
import SeachBox2 from "../../assets/images/Rectangle 8.png";
import Malph from "../../assets/images/Rectangle 14.png";
import alph2 from "../../assets/images/Rectangle 286.png";
import alph3 from "../../assets/images/Rectangle 289.png";
import alph4 from "../../assets/images/Rectangle 294.png";
import rect from "../../assets/images/Rectangle 11.png";
import searcher from "../../assets/images/Rectangle 9 (1).png";
import sort1 from "../../assets/images/Rectangle 10 (1).png";
import sort2 from "../../assets/images/Rectangle 8 (1).png";
import triangle from "../../assets/images/Polygon 9.png";
import akar from "../../assets/images/akar-icons_search (4).png";

function DataOfUsers() {
  const data = [
    { name: "Royal Gala Apple" },
    { name: "Organic Potatoes" },
    { name: "Bok choi" },
    { name: "Hide n Seek" },
    { name: "Cadbury Oreo" },
    { name: "Royal Gala Apple" },
  ];

  const data2 = [
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Green,
      category: "Fruits & Vegetables",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Dairy,
      category: "Dairy & Eggs",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Sweets,
      category: "Sweet Tooth",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Cleaner,
      category: "Kitchen & Cleaning",
      subCategory: "Sub Categories",
    },
    {
      id: Math.ceil(Math.random() * 1000),
      img: Bread,
      category: "Bread & Baker",
      subCategory: "Sub Categories",
    },
  ];
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(data2.length / itemsPerPage);
  const currentItems = data2.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data2.length;
    setItemOffset(newOffset);
  };
  return (
    <MainTemplate categoryName="Categories" categoryList={["Admin", "Users"]}>
      <div className="row mb-3 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2 col-1">
          <select
            className=" t-12 form-select"
            aria-label="Default select example"
            outline="none"
          >
            <option selected>Sort By</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-1 col-1">
          <select
            className=" t-12 form-select"
            aria-label="Default select example"
            outline="none"
          >
            <option selected>10</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>{" "}
        </div>
        <div className="col-3 my-3">
          <input
            type="text"
            className="t-12 form-control"
            placeholder="Search"
          />
        </div>
        <div className="col-3"></div>
        <div className="col-2 my-3">
          <button
            id="btnClear"
            className="btn btn-dark m-2 text-warning"
            onClick="btnClear_Click"
          >
            Action
            <img src={triangle} width="20" />
          </button>{" "}
        </div>
      </div>

      <div className="row mb-4 rounded p-2 bg-white">
        <div className="row rounded px-3 bg-white">
          {/* header */}
          <div className="row shift">
            <div className="col-1 font-weight-bold m-0 mt-1 mb-1">
              <input type="checkbox" id="vehicle1"></input>
            </div>
            <div className="col-1 font-weight-bold mt-1 mb-1">ID</div>
            <div className="col-1 font-weight-bold mt-1 mb-1">PHOTO</div>
            <div className="col-2 font-weight-bold afs m-4 mt-1 mb-1">
              FULLNAME
            </div>
            <div className="col-2 font-weight-bold mt-1 mb-1">EMAIL</div>
            <div className="col-2 font-weight-bold mt-1 mb-1 m-4">DATE</div>
          </div>
        </div>
        {/* body */}
        <div className="container">
          <div className="row mech">
            <div className="col-1">
              <div className="font-weight-bold  my-4">
                <input type="checkbox" id="vehicle1"></input>
              </div>
            </div>
            <div className="col my-4 size">#6781</div>
            <div className="col-1  my-2">
              <img src={Malph} alt="img" />
            </div>
            <div className="col-2 my-4 size ags m-2">Murli Prasad</div>

            <div className="col-2 my-4 size m-2">murli1@gmail.com</div>
            <div className="col my-4 size ends m-2">11/1/2001</div>
            <div className="col-1"></div>

            <div className="col my-4 size">
              <input
                type="image"
                src={Dots}
                name="saveForm"
                class="btTxt submit"
                id="saveForm"
              />{" "}
            </div>
          </div>

          <div className="row mech">
            <div className="col-1 d-flex align-items-center">
              <div className="font-weight-bold  my-4">
                <input type="checkbox" id="vehicle1"></input>
              </div>
            </div>
            <div className="col my-4 size">#6782</div>
            <div className="col-1 my-2">
              <img src={alph2} alt="img" />
            </div>
            <div className="col-2 my-4 size ags m-2">Salman Hadid</div>

            <div className="col-2 my-4 size m-2">salmand@gmail.com</div>
            <div className="col my-4 size m-2">02/1/2001</div>
            <div className="col-1"></div>

            <div className="col my-4">
              <input
                type="image"
                src={Dots}
                name="saveForm"
                class="btTxt submit"
                id="saveForm"
              />{" "}
            </div>
          </div>

          <div className="row mech">
            <div className="col-1 d-flex align-items-center">
              <div className="font-weight-bold  my-4">
                <input type="checkbox" id="vehicle1"></input>
              </div>
            </div>
            <div className="col my-4 size">#6783</div>
            <div className="col-1 my-2">
              <img src={alph3} alt="img" />
            </div>
            <div className="col-2 my-4 size ags m-2">Bahadur Ali</div>

            <div className="col-2 my-4 size m-2">bahadurali789@gmail.com</div>
            <div className="col my-4 size m-2">1/10/2001</div>
            <div className="col-1"></div>

            <div className="col my-4">
              <input
                type="image"
                src={Dots}
                name="saveForm"
                class="btTxt submit"
                id="saveForm"
              />{" "}
            </div>
          </div>

          <div className="row mech">
            <div className="col-1 d-flex align-items-center">
              <div className="font-weight-bold  my-4">
                <input type="checkbox" id="vehicle1"></input>
              </div>
            </div>
            <div className="col my-4 size">#6784</div>
            <div className="col-1 my-2">
              <img src={Malph} alt="img" />
            </div>
            <div className="col-2 my-4 size ags m-2">Manaya Jha</div>

            <div className="col-2 my-4 size m-2">manaya.jha123@gmail.com</div>
            <div className="col my-4 size m-2">11/10/2001</div>
            <div className="col-1"></div>

            <div className="col my-4">
              <input
                type="image"
                src={Dots}
                name="saveForm"
                class="btTxt submit"
                id="saveForm"
              />{" "}
            </div>
          </div>

          <div className="row mech">
            <div className="col-1 d-flex align-items-center">
              <div className="font-weight-bold  my-4">
                <input type="checkbox" id="vehicle1"></input>
              </div>
            </div>
            <div className="col my-4 size">#6785</div>
            <div className="col-1 my-2">
              <img src={alph4} alt="img" />
            </div>
            <div className="col-2 my-4 size ags m-2">Gopi Rao</div>

            <div className="col-2 my-4 size m-2">gopirao456@gmail.com</div>
            <div className="col my-4 size m-2">02/1/2001</div>
            <div className="col-1"></div>

            <div className="col my-4">
              <input
                type="image"
                src={Dots}
                name="saveForm"
                class="btTxt submit"
                id="saveForm"
              />{" "}
            </div>
          </div>
        </div>

        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </MainTemplate>
  );
}

export default DataOfUsers;
