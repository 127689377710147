import React, { useEffect, useState, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Await, Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Modal, Form, Container, Spinner } from "react-bootstrap";

import { getApi, getTokenApi, postTokenApi } from "../../api_interface/apis";
import { FRANCHISE_PROFILE, FRANCHISE_USERS_LIST, HIBEE_AUTH_URL, HIBEE_PRODUCT_URL, OFFLINE_USER, SOCIETY_LIST, USERS } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import { addUser } from "../../redux/reducers/userCartSlice";
import "../UserListing/UserListing.css";
import { debounce } from "../../utils/helperFunctin";
import axios from "axios";
import { toast } from "react-toastify";


function UserListing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [franchiseUsersList, setFranchiseUsersList] = useState([])
  const [franchiseProfileList, setFranchiseProfileList] = useState([])
  const user = useSelector((state) => state.userReducer);
  const [itemOffset, setItemOffset] = useState(0);
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [showModal, setShowModal] = useState()
  const [societies, setSocieties] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [societyId, setsocietyId] = useState('');
  const [CurrentSociety, setCurrentSociety] = useState([])
  const [societyList, setSocietyList] = useState([])
  const [towerList, setTowerList] = useState([])
  const [VillaList, setVillaList] = useState([])
  const [floorsList, setFloorsList] = useState([])
  const [userid, setUserId] = useState()
  const [userData, setUserData] = useState({
    userName: '',
    phone: '',
    email: '',
    towerVillaType: "",
    society: [],
    tower: [],
    villa: [],
    floor: [],
    flat: "",
    _id: "",
    frenchise_id: ""
  });


  console.log(userData,'>> UserData');

  const [userDataError, setUserDataError] = useState({
    userName: '',
    phone: '',
    email: '',
    towerVillaType: "",
    society: "",
    tower: "",
    villa: "",
    floor: "",
    flat: "",
    _id: "",
    frenchise_id: ""
  });



  /** haandle change **/
  const handleFranchisechange = (event) => {
    // if (event.target.name == "society") {
    //   const selectedSocietyName = event.target.value;
    //   const selectedSocietyObject = societyList.find((el) => el.societyName == selectedSocietyName);
    //   setUserData({
    //     ...userData,
    //     society: selectedSocietyObject ? selectedSocietyObject : []
    //   });
    //   setTowerList(selectedSocietyObject ? selectedSocietyObject?.towers : []);
    //   setVillaList(selectedSocietyObject ? selectedSocietyObject?.villas : []);
    // }

    if (
      event.target.name != "tower" &&
      event.target.name != "villa" &&
      event.target.name != "floor"
    ) {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
      });
      setUserDataError({
        ...userDataError,
        [event.target.name]: null,
      });
    }

    if (event.target.name == "tower") {
      const selectedTowerName = event.target.value;
      const selectedTowerObject = towerList.find((el) => el.name == selectedTowerName);
      setUserData({
        ...userData,
        tower: selectedTowerObject ? selectedTowerObject : [],
        towerVillaType: 'TOWER',
        villa: [],
      });
      setFloorsList(selectedTowerObject ? selectedTowerObject?.floors : [])
      setVillaList([])
      setTimeout(() => {
        setVillaList(userData?.society?.villas)
      }, [1000])
    }
    if (event.target.name == "villa") {
      const selectedVillaName = event.target.value;
      const selectedVillaObject = VillaList.find((el) => el.villaName == selectedVillaName);
      setUserData({
        ...userData,
        villa: selectedVillaObject ? selectedVillaObject : [],
        towerVillaType: 'VILLA',
        tower: [],
        flat: "",
        floor: []
      });
      setTowerList([])
      setTimeout(() => {
        setTowerList(userData?.society?.towers)
      }, [1000])
    }

    if (event.target.name == "floor") {
      const selectedFloorName = event.target.value;
      const selectedFloorObject = floorsList.find((el) => el.floorName == selectedFloorName);
      setUserData({
        ...userData,
        floor: selectedFloorObject ? selectedFloorObject : []
      });
    }

  };



  const handleValidation = () => {
    let newErrors = {};

    if (userData.userName == '') {
      newErrors.userName = 'Please enter your name.';
    }
    if (userData.phone == '') {
      newErrors.phone = 'Please enter your phone number.';
    } else if (userData.phone.length !== 10) {
      newErrors.phone = 'Phone number should be 10 digits long.';
    }

    if (userData.email == '') {
      newErrors.email = 'Please enter your Email.';
    }

    if (userData.society.length === 0) {
      newErrors.society = 'Please enter your society.';
    }

    if (userData.towerVillaType == "TOWER") {
      if (userData.tower.length == 0) {
        newErrors.tower = "please select tower";
      }
      if (userData.floor.length == 0) {
        newErrors.floor = "please select floor";
      }
      if (userData.flat == '') {
        newErrors.flat = "please enter flat number";
      }
    }

    if (userData.towerVillaType == "VILLA") {
      if (userData.villa.length == 0) {
        newErrors.villa = "please select villa";
      }
    }
    return newErrors
  };
console.log(userData,'>>>Data');
  const handleSubmit = async () => {
    const frenchiseId = user?.frenchise_id
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setUserDataError(handleValidationObject)
    } else {
      try {
        // setLoader(true)
        const payload = {
          name: userData.userName,
          phone: userData.phone,
          society: userData.society?._id,
          tower: userData?.tower?._id,
          email: userData.email,
          floor: userData?.floor?.floorName,
          frenchise_id: user.frenchiseId,
          flat: userData?.flat
        };
        const response = await postTokenApi(HIBEE_AUTH_URL + OFFLINE_USER, payload);
        if (response.status == 200) {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
          });
          setLoader(false);
          handleClear();
          setShowModal(false);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }

        //   const token = localStorage.getItem("x-access-token")
        //   fetch('https://hybee-auth.moshimoshi.cloud/offline_user/register', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'x-access-token': token,
        //     },
        //     body: JSON.stringify(payload),
        //   })
        //     .then(response => response.json())
        //     .then(data => {
        //       // Handle the response data
        //       console.log(data);
        //     })
        //     .catch(error => {
        //       // Handle any errors
        //       console.error(error);
        //     });

      } catch (err) {
        const message = err?.response?.data?.message || "Something went wrong";
        toast.error(message, { position: "top-center", autoClose: 2000 });
      }
    }
  };




  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handleGetUsersList = async search => {
    console.log(search, "searchsearchsearchsearch")
    setLoader(true)
    if (user?.frenchise_id) {
      const frenchiseId = user?.frenchise_id
      await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_USERS_LIST + `${frenchiseId}` + `?sort=${sort}&search=${search}`).then((ele, ind) => {
        if (ele.status == 200) {
          setFranchiseUsersList(ele?.data?.users)
          setLoader(false)

        }
      }).catch((error) => {
        setLoader(false)
      })
    }
  }
  // console.log(franchiseProfileList,"franchiseProfileListfranchiseProfileListfranchiseProfileListfranchiseProfileListfranchiseProfileList")

  const handleGetFranchiseProfile = async () => {
    setLoader(true)
    if (user?.frenchise_id) {
      // const frenchiseId = user?.frenchise_id
      await getTokenApi(HIBEE_AUTH_URL + USERS).then((ele, ind) => {
        if (ele.status == 200) {
          setFranchiseProfileList(ele?.data?.data?.frenchiseDetails)
          setLoader(false)
        }
      }).catch((error) => {
        setLoader(false)
      })
    }
  }
  // console.log(franchiseProfileList,"res?.data?.data?.frenchiseDetailsres?.data?.data?.frenchiseDetailsres?.data?.data?.frenchiseDetails")


  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    handleClear()
    setShowModal(false)
  };
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(franchiseUsersList.length / itemsPerPage);
  const franchiseUsers = franchiseUsersList.slice(itemOffset, endOffset);

  console.log(franchiseUsers, 'jjgjgj');

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % franchiseUsersList.length;
    setItemOffset(newOffset);
  };
  const handleUserSelect = user => {
    dispatch(addUser(user))
    navigate("/product-listing")
  }
  const handleSelectuser = user => {
    navigate("/userInfo")
  }

  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    handleGetUsersList(search)
  }, 500), [])


  useEffect(() => {
    handleGetUsersList(query)
  }, [sort, query])


  useEffect(() => {
    handleGetFranchiseProfile()
  }, [])



  const getFranchiseData = async () => {
    // const franchise = user?.frenchise_id;
    const franchiseIdResponse = await getTokenApi(HIBEE_AUTH_URL + USERS);
    if (franchiseIdResponse.status == 200) {
      const franchiseId = franchiseIdResponse?.data?.data?.frenchiseDetails?.society._id;
      setsocietyId(franchiseId)

    }

  }
  const societyResponce = async () => {
    try {
      const response = await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST);
      if (response.status == 200) {
        const societyData = response.data.data;
        if (societyId != '') {
          const targetSociety = societyData.find(item => item._id === societyId);
          setCurrentSociety(targetSociety)
          setUserData({
            ...userData,
            society: targetSociety
          })
          setTowerList(targetSociety ? targetSociety?.towers : []);
          setVillaList(targetSociety ? targetSociety?.villas : []);
        }
      }
    } catch (error) {
      console.error('Error fetching society data:', error);
    }
  };
console.log(towerList,">> TowerList");
  useEffect(() => {
    getFranchiseData()
    societyResponce();
  }, [societyId]);


  const handleClear = () => {
    setUserDataError({
      ...userDataError,
      userName: '',
      phone: '',
      email: '',
      towerVillaType: "",
      society: "",
      tower: "",
      villa: "",
      floor: "",
      flat: "",
      _id: "",
      frenchise_id: ""
    })

    setUserData({
      ...userData,
      userName: '',
      phone: '',
      email: '',
      towerVillaType: "",
      society: [],
      tower: [],
      villa: [],
      floor: [],
      flat: "",
      _id: "",
      frenchise_id: ""

    })
  }

  console.log(userDataError, 'userDataError');
  console.log(userData, '11111111');



  return (
    <MainTemplate categoryName="User Listing" categoryList={[" "]} >
      <div className="row mb-3 rounded p-2 bg-white">
        {/* <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div> */}
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input
            type="text"
            name="query"
            className="t-14 form-control"
            placeholder="Search Name or ID"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-md-6 my-3 d-flex justify-content-end">
          <button className="btn t-12 w-25 btn_bg rounded text-warning fw-bold d-flex align-items-center justify-content-evenly fs-6" onClick={handleButtonClick}>
            <span className="fs-4 d-flex align-items-center ">  +</span> New User</button>
        </div>
      </div>

      <div className="row rounded px-3">
        {/* header */}
        <div className="row rounded px-3 mb-1 t-14">
          <div className="row m-0 user-mobile">
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              Flat Number
            </div>
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              Name
            </div>
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              Phone
            </div>
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              Tower
            </div>
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              Floor
            </div>
            <div className="font-weight-bold text-uppercase my-3 col-2 d-flex justify-content-center">
              order
            </div>
          </div>
        </div>
        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">

          {
            loader == false && franchiseUsers && franchiseUsers.map((ele, ind) => {
              return <div className="row rounded px-3 bg-white mb-3 t-14">
                <div className="row m-0 user-mobile">
                  <div className="my-3 col-2 d-flex d-flex justify-content-center">
                    {ele.flat_no}
                  </div>
                  <div className="my-3 col-2 d-flex d-flex justify-content-center">
                    <Link to={`/user-cart/${ele?._id}`} style={{ textDecoration: "none" }}>
                      {ele?.name}
                    </Link>
                  </div>
                  <div className="my-3 col-2 d-flex d-flex justify-content-center">
                    <Link to={`/userInfo/${ele._id}`}>
                      {ele?.phone}
                    </Link>
                  </div>
                  <div className="my-3 col-2 d-flex d-flex justify-content-center">
                    {ele?.tower}
                  </div>
                  <div className="my-3 col-2 d-flex d-flex justify-content-center">
                    {ele?.floor}
                  </div>
                  <div className="t-12 my-3 col-2 d-flex justify-content-center user-select">
                    <button className="btn btn-outline-dark py-1 px-3 w-100" onClick={() => handleUserSelect(ele)}>Select</button>
                  </div>
                </div>
              </div>
            })
          }
          {loader == true &&
            <div className="text-center mt-5" >
              <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                Loading...
              </button>
            </div>
          }
        </div>
        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>

        {/* User Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="mt-4">New User Form</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                name="userName"
                className={`${userDataError?.userName ? "border-danger" : ""} form-control `}
                value={userData.userName}
                onChange={handleFranchisechange}
                placeholder="Enter your Name"
              >
              </Form.Control>
              <span className="text-danger">{userDataError.userName}</span>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Society/Community">
              <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="number"
                name="phone"
                className={`${userDataError?.phone ? "border-danger" : ""} form-control`}
                value={userData.phone}
                onChange={handleFranchisechange}
                placeholder="+91"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 10); // Limit input to 10 characters
                }}
              >
              </Form.Control>
              <span className="text-danger">{userDataError.phone}</span>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Society/Community">
              <Form.Label>Email <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="email"
                name="email"
                className={`${userDataError?.email ? "border-danger" : ""} form-control`}
                value={userData?.email}
                onChange={handleFranchisechange}
                placeholder="@.com"
              />
              <span className="text-danger">{userDataError.email}</span>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Society/Community">
              <Form.Label>Society <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="society"
                name="society"
                className={`${userDataError?.societyName ? "border-danger" : ""} form-control`}
                value={CurrentSociety?.societyName}
                disabled
                onChange={handleFranchisechange}
                placeholder="Enter your society"
              />
              <span className="text-danger">{userDataError.societyName}</span>
            </Form.Group>


            <div className="col mb-3">
              <div>
                <Form.Label>Select Tower or Villa{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
              </div>
              <Form.Label>Tower
                {/* <span className="text-danger">*</span> */}
              </Form.Label>
              <Form>
                <Form.Select
                  controlId="Product category"
                  type="text"
                  placeholder="Enter select product category"
                  name="tower"
                  // className={`Textbar`}
                  className={`${userDataError?.tower ? "border-danger" : ""} Textbar`}
                  onChange={handleFranchisechange}
                  autoComplete="off"
                  value={userData?.tower?.towerName}
                >
                  <option value=''>Select tower</option>
                  {/* <optgroup value='Towers List' label="Towers List"> */}
                  {
                    towerList?.map((ele, ind) => {
                      return <option value={ele?.name}  >{ele?.name}</option>
                    })
                  }
                  {/* </optgroup> */}
                </Form.Select>
              </Form>
              <span className="text-danger"> {userDataError?.tower} </span>
            </div>


            <div className="col mb-3">
              OR
            </div>

            <div className="col mb-3">
              <Form.Label>Villa
                {/* <span className="text-danger">*</span> */}
              </Form.Label>
              <Form>
                <Form.Select
                  controlId="Product category"
                  type="text"
                  placeholder="Enter select product category"
                  name="villa"
                  // className={`Textbar`}
                  className={`${userDataError?.villa ? "border-danger" : ""} Textbar`}
                  autoComplete="off"
                  onChange={handleFranchisechange}
                  value={userData?.villa?.villaName}
                >
                  <option value=''>Select villa</option>
                  {/* <optgroup value='Towers List' label="Towers List"> */}
                  {
                    VillaList?.map((ele, ind) => {
                      return <option value={ele?.name}  >{ele?.name}</option>
                    })
                  }
                  {/* </optgroup> */}
                </Form.Select>
              </Form>
              <span className="text-danger"> {userDataError.villa} </span>
            </div>


            {
              userData?.tower?.length != 0 && userData?.towerVillaType == 'TOWER'
                ?
                <div>
                  <div className="col mb-3">
                    <Form.Label>Floor <span className="text-danger">*</span></Form.Label>
                    <Form.Select
                      // className="Textbar"
                      controlId="Product category"
                      type="text"
                      placeholder="Enter select product category"
                      name="floor"
                      className={`${userDataError.floor ? "border-danger" : ""} Textbar`}
                      onChange={handleFranchisechange}
                      value={userData?.floor?.floorName}
                      autoComplete="off"
                    >
                      <option value='' > Select Floor</option>
                      {
                        floorsList?.map((el, index) => (
                          <option
                            key={index}
                            value={el.floorName}
                          >
                            {el?.floorName}
                          </option>
                        ))
                      }
                    </Form.Select>
                    <span className="text-danger"> {userDataError.floor} </span>
                  </div>

                  <Form.Group className="mb-3" controlId="Society/Community">
                    <Form.Label>Flat Number <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className={`${userDataError.flat ? "border-danger" : ""} Textbar`}
                      type="number"
                      placeholder="type your Flat Number hear"
                      name="flat"
                      onChange={handleFranchisechange}
                      value={userData?.flat}
                      autoComplete="off"
                      onWheelCapture={(e) => e.target.blur()}
                    />
                    <span className="text-danger">{userDataError.flat}</span>
                  </Form.Group>
                </div>
                :
                ''
            }
            <Modal.Footer className="d-flex justify-content-start ms-3" >
              <button className="btn t-12 w-25  rounded btn-dark text-warning" onClick={handleSubmit} variant="primary"  >
                Submit
              </button>

              <Button className="btn t-12 w-25  rounded btn-dark text-warning" onClick={handleClear} variant="primary"  >
                Clear
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>


      </div>

    </MainTemplate >
  );
}

export default UserListing;