import React from "react";
import "./InventoryProductAddForm.css";
import MainTemplate from "../../../components/MainTemplate";

function InventoryProductAddForm() {

	return (
		<MainTemplate categoryName="Inventory" categoryList={["Admin", "Inventory", "Add"]}>
			<div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
				<div className="col-lg-9 mb-4">
					<div className="form-group py-2 pb-3">
						<label>Product Code</label>
						<input type="text" className="w-25 placeholder-light form-control py-2 px-3" placeholder="#123456" />
					</div>
					<div className="form-group py-2 pb-3">
						<label>Product Name</label>
						<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="Product name here" />
					</div>
					<div className="form-group py-2 pb-3">
						<label>Product variant</label>
						<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="Enter product variant" />
					</div>
					<div className="form-group py-2 pb-3">
						<label>Manufacture Name</label>
						<input type="text" className="w-75 placeholder-light form-control py-2 px-3" placeholder="Enter manufacture name" />
					</div>
					<div className="row d-flex w-75 m-0 p-0">
						<div className="col-lg-6 form-group p-0 py-2 pb-3 pe-2">
							<label>Product MFG Date</label>
							<input type="date" className="placeholder-light form-control py-2 px-3" placeholder="Enter manufacture name" />
						</div>
						<div className="col-lg-6 form-group p-0 py-2 pb-3">
							<label>Best Before/Expiry</label>
							<input type="date" className="placeholder-light form-control py-2 px-3" placeholder="Enter manufacture name" />
						</div>
					</div>
					<div className="row d-flex w-75 m-0 p-0">
						<div className="col-lg-6 form-group p-0 py-2 pb-3 pe-2">
							<label>Product Qty</label>
							<input type="text" className="placeholder-light form-control py-2 px-3" placeholder="Enter manufacture name" />
						</div>
						<div className="col-lg-6 form-group p-0 py-2 pb-3">
							<label>Select Warehouse</label>
							<select className="py-2 px-3 form-select" aria-label="Default select example" outline="none">
								<option value="0" selected>Whitefield</option>
								<option value="1">London</option>
							</select>
						</div>
					</div>
					<div className="form-group py-2 pb-3">
						<label>Product Category</label>
						<select className="w-75 py-2 px-3 form-select" aria-label="Default select example" outline="none">
							<option selected disabled>Select category</option>
							<option value="1">One</option>
							<option value="2">Two</option>
							<option value="3">Three</option>
						</select>
					</div>
					<div className="row d-flex w-75 m-0 p-0">
						<div className="col-lg-6 form-group p-0 py-2 pb-3 pe-2">
							<label>Storage Type</label>
							<input type="text" className="placeholder-light form-control py-2 px-3" placeholder="Enter storage name" />
						</div>
						<div className="col-lg-6 form-group p-0 py-2 pb-3">
							<label>Product Batch</label>
							<input type="text" className="placeholder-light form-control py-2 px-3" placeholder="Enter batch name" />
						</div>
					</div>
				</div>
				<div className="col-lg-3 mb-4">
					<div className="py-2">
						<label>Product variant</label>
						<div className="p-4 border rounded">
							<img  className="w-100" src="https://t4.ftcdn.net/jpg/00/99/68/91/240_F_99689120_Np9qTnB8MfAzuexFc2pLFrP527qvXvDk.jpg" />
						</div>
					</div>
					<div className="py-2">
						<label>Product variant</label>
						<div className="p-4 border rounded">
							<img  className="w-100" src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz=w240-h480-rw" />
						</div>
					</div>
					<div className="py-2">
						<label>Product variant</label>
						<div className="p-4 border rounded">
							<img  className="w-100" src="https://www.bigbasket.com/media/uploads/p/xxl/265894_7-aquafina-packaged-drinking-water.jpg" />
						</div>
					</div>
				</div>
				<div className="form-group py-2 pb-3">
					<button className="btn btn-dark text-warning px-5">Save Product</button>
				</div>
			</div>
		</MainTemplate>
	);
}

export default InventoryProductAddForm;