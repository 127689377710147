import React, { useEffect, useState } from "react";
import "./Referral.css";
import MainTemplate from "../../components/MainTemplate";
import { useSelector } from "react-redux";
import { getApi } from "../../api_interface/apis";
import { FRANCHISE_PROFILE, HIBEE_PRODUCT_URL } from "../../api_interface/apiURLs";

function Referral() {
    const [referalData, setReferalData] = useState()
    const [loader, setLoader] = useState()

    const user = useSelector((state) => state.userReducer);

    /** Get Profile Detailes **/
    const handleGetFranchiseProfile = async () => {
        setLoader(true)
        if (user?.frenchise_id) {
            const frenchiseId = user?.frenchise_id
            await getApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + `/${frenchiseId}`).then((ele, ind) => {
                if (ele.status == 200) {
                    setReferalData(ele?.data?.data)
                    setLoader(false)
                }
            }).catch((error) => {
                console.log(error);
                // setLoader(false)
            })
        }
    }

    useEffect(() => {
        handleGetFranchiseProfile()
    }, [])
    return (
        <MainTemplate categoryName="Liquid Cash" categoryList={["Finances", "Liquid Cash"]}>
            <div className="px-4 py-5 rounded bg-white">
                <div className="d-flex flex-column position-relative align-items-center justify-content-center" style={{ height: "70vh" }}>
                    <div className="w-100 shadow-sm referral_background" style={{ flex: "0.5" }} />

                    <div className="w-50 bg-white rounded px-5 py-4 position-absolute shadow d-flex justify-content-between referralCode">
                        {
                            loader == false && <span>{referalData && referalData?.referral_id}</span>
                        }
                        {
                            loader == true && <span class="spinner-border" role="status">
                            </span>
                        }
                        <span>
                            <svg width="26" className="cursorPointer" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.66667 0C7.5174 0 6.41519 0.34241 5.60254 0.951903C4.78988 1.5614 4.33333 2.38805 4.33333 3.25V19.5C4.33333 20.362 4.78988 21.1886 5.60254 21.7981C6.41519 22.4076 7.5174 22.75 8.66667 22.75H21.6667C22.8159 22.75 23.9181 22.4076 24.7308 21.7981C25.5435 21.1886 26 20.362 26 19.5V3.25C26 2.38805 25.5435 1.5614 24.7308 0.951903C23.9181 0.34241 22.8159 0 21.6667 0H8.66667ZM6.5 3.25C6.5 2.81902 6.72827 2.4057 7.1346 2.10095C7.54093 1.7962 8.09203 1.625 8.66667 1.625H21.6667C22.2413 1.625 22.7924 1.7962 23.1987 2.10095C23.6051 2.4057 23.8333 2.81902 23.8333 3.25V19.5C23.8333 19.931 23.6051 20.3443 23.1987 20.649C22.7924 20.9538 22.2413 21.125 21.6667 21.125H8.66667C8.09203 21.125 7.54093 20.9538 7.1346 20.649C6.72827 20.3443 6.5 19.931 6.5 19.5V3.25ZM0 6.5C1.93233e-05 5.92952 0.200256 5.36909 0.580584 4.87505C0.960913 4.381 1.50793 3.97074 2.16667 3.6855V20.3125C2.16667 21.3899 2.73735 22.4233 3.75317 23.1851C4.76899 23.947 6.14674 24.375 7.58333 24.375H21.086C20.7057 24.8691 20.1587 25.2793 19.4999 25.5646C18.8412 25.8498 18.094 26 17.3333 26H7.58333C5.57211 26 3.64326 25.4008 2.22111 24.3342C0.798957 23.2676 0 21.8209 0 20.3125V6.5Z" fill="black" />
                            </svg>
                        </span>
                    </div>

                    <div className="w-100 shadow-sm referral_background2 bg-light d-flex justify-content-center align-items-center" style={{ flex: "0.5" }}>
                        <div className="row eferral-desktop">
                            <div className="col-8 p-0 t-14">
                                <div className="font-weight-bold">COMMISSION</div>
                                <div className="">Your total comission from refering</div>
                            </div>
                            <div className="col-4 p-0 t-32 d-flex justify-content-end">₹ <span className="font-weight-bold">300</span></div>

                            <div className="cursorPointer d-flex justify-content-center mt-5"><u>Invite your friend to Hibee</u></div>
                        </div>
                    </div>
                </div>
            </div>
        </MainTemplate>
    );
}

export default Referral;
