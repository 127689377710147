import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import "../CODActive/CODActive.css";
import Smallcircle from "../../assets/images/Ellipse 16.png";
import MainTemplate from "../../components/MainTemplate";
import img4 from "../../assets/images/revenue/img4.png"
import img2 from "../../assets/images/revenue/Vector.png";
import FranchiseDetails from "../superAdmin/FranchiseDetails/FranchiseDetails";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CODLIMIT, COD_TRANSACTIONS, FRANCHISE_ORDER, FRANCHISE_PROFILE, HIBEE_ORDER_URL, HIBEE_PRODUCT_URL } from "../../api_interface/apiURLs";
import { getApi, getTokenApi } from "../../api_interface/apis";
import { useSelector } from "react-redux";
import moment from "moment";



function CODActive() {
  const [franchisedetails, setFranchiseDetails] = useState([])
  const [loader, setLoader] = useState(false)
  const user = useSelector((state) => state.userReducer);
  const [monthamount, setMonthAmount] = useState([])
  const [franchiseLimit, setFranchiseLimit] = useState()
  const [codlist, setCodList] = useState(); 
  const [codamount, setCodAmount] =useState()
  const [codstatus, setCodStatus] = useState(Boolean);
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);
  const [loadingFranchiseDetails, setLoadingFranchiseDetails] = useState(true);



  const handleGetFranchiseProfile = async (page) => {
    setLoader(true)

    try {
      const response =  await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + COD_TRANSACTIONS)
      const responseData = response?.data.data
      setCodList(responseData)
    }
   catch(error){
        console.log(error);
        setLoader(false)
      }
    }
  

  const handleGetRevenue= async()=>{
    setLoader(true)
    try {
      const response =   await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + CODLIMIT )
      const responseData = response.data.data
      const currentamount = response?.data?.data;
    const COD= currentamount?.cod_status
    setCodAmount(COD)
    setCodStatus(responseData)
    console.log(currentamount,"statusstatusstatusstatusstatusstatus")
    }
   catch(error){
        console.log(error);
        setLoader(false)
      }
  
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingRevenue(true);
        const revenueResponse = await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + CODLIMIT);
        const revenueData = revenueResponse.data.data;
        setCodAmount(revenueData.cod_status);
        setLoadingRevenue(false);
      } catch (error) {
        console.log(error);
        setLoadingRevenue(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingFranchiseDetails(true);
        const franchiseResponse = await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_PROFILE + COD_TRANSACTIONS);
        const franchiseData = franchiseResponse.data.data;
        setCodList(franchiseData);
        setLoadingFranchiseDetails(false);
      } catch (error) {
        console.log(error);
        setLoadingFranchiseDetails(false);
      }
    };

    fetchData();
  }, []);


const Example = () => {
  const [startDate, setStartDate] = useState(new Date());
  
};

  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")

  useEffect(() => {
      
  }, [sort])

useEffect(()=>{
  handleGetRevenue()
},[])

  const updateSort = e => {
    setSort(e.target.value)
  }
  useEffect(() => {
    handleGetFranchiseProfile()
  }, [])
  return (
    <>
    {loadingRevenue || loadingFranchiseDetails ? (
      <div className="text-center position-absolute top-50 start-50 bg-light">Loading...</div>
  
   ):  codamount === false ? (
    <MainTemplate categoryName="Liquid Cash" categoryList={["Finances", "Liquid Cash"]}>
      
    <div className="row  rounded bg-white">
        <div className="col-lg-6 ">

        <div className="row m-1 mt-4 mb-3 font-weight-bold t-12 fs-6 w-100">COD BLOCKED</div>

        <div className="row m-1 mb-3 font-weight-bold t-12">
            <div className="w-50 p-0">
                No more cash on delivery from your community, pay the unpaid balance and send an email to active cod
            </div>
        </div>
        <div className="row m-1 font-weight-bold t-12">
            <div className="w-50 p-0">
                <button className="btn bg-dark text-warning px-4" onClick={handleShow} >Contact Hibee Admin</button>
              
            </div>
        </div>
        <div className="row m-1 my-4 t-14">
            <div className="d-flex p-0 justify-content-center">
            </div>
        </div>
        </div>

    <div className="col-lg-5">
    <div className="p-4 ">
        <div className="row m-1 mb-2 t-14">
            <div className=" border rounded p-3 d-flex justify-content-between">
                <span>COD Order Status</span>
                <span className="d-flex align-items-center"><span className="dot redDot mx-2" />Blocked</span>
            </div>
        </div>
        <div className="row m-1 mb-2 t-14">
            <div className=" border rounded p-3 d-flex justify-content-between">
                <span>Unpaid Balance</span>
                <span className="d-flex align-items-center">₹{codstatus.cod_amount} </span>
            </div>
        </div>
        <div className="row m-1 my-4 t-14">
            <div className=" d-flex p-0 justify-content-around ">
                <p className="text-danger"> Your payment was due</p>
                {/* <button className="btn bg-dark text-warning px-4">Pay</button> */}
            </div>
        </div>

    </div>

    </div>
    
    </div>
    <div className="row mb-4 rounded px-3 bg-white">
{/* header */}
<div className="row m-0 ">
  <div className="font-weight-bold t-12 tFext-uppercase my-3 col-md-3">
    Date
  </div>
  <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
  Amount paid
  </div>
  <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
  Paid to
  </div>
  <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">

  </div>
</div>
<div className="m-0 p-0 px-3 overflow-auto caterogyPageScroll">
  {
    codlist?.map(el => (
      <div className="row border rounded mb-2 ">
        <div className="t-12 my-3 col-md-3">
        {moment(el?.payment_date).format("D MMM, YYYY")}
        </div>
        <div className="t-12 my-3 col-md-3">
        {el?.amount}
        </div>
        <div className="t-12 my-3 col-md-3">
        {el?.status}
        </div>
        <div className="t-12 d-flex justify-content-center  p-0 my-3 col-md-3">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16" >
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg> */}
        </div>
      </div>

    ))
  }
</div>
</div>
<Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Admin Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  <p>Name <p className="fw-bold">Bharath </p></p>
  <p>Phone <p className="fw-bold" > +91{' '}264184518454 </p></p>
   </Modal.Body>
<Modal.Footer>

  <Button className="btn bg-dark text-warning" onClick={handleClose}>
  Close
  </Button>
</Modal.Footer>
</Modal>         
</MainTemplate>
   ):(
    
     <MainTemplate
     categoryName="Liquid Cash"
     categoryList={["Franchise", "Liquid Cash"]}
   >
     <div className="main_container row container " id="contanee-adjust">
       <div className="col-6">
       <>
       <div id="hightsmall"></div>
       <div className="row llongrow">
         <div className="col-6 rrowsheight codestart boxallrows">
           <div className="my-3 magerfont ">COD Order Status</div>
           <div className="my-3 lessfont">
             <img src={Smallcircle} /> Active
           </div>
         </div>
       </div>
      
       {/* <div className="row mt-4 llongrow">
         <div className="col-6 rrowsheight codestart boxallrows">
           <div className="my-3 magerfont">Unpaid Balance</div>
           <div className="my-3 Payingfont">₹{codstatus.cod_amount} </div>
           </div>
          </div> */}
       <div className="row mt-4 llongrow">
         <div className="col-6 rrowsheight codestart boxallrows">
           <div className="my-3 magerfont">Franchise Balance</div>
           <div className="my-3 Payingfont">₹ {codstatus.maximum_COD_amount}</div>
         </div>
         
       </div>
       <div className="row mt-4 llongrow">
         <div className="col-6 rrowsheight codestart boxallrows">
           <div className="my-3 magerfont">Franchise Current Balance</div>
           <div className="my-3 Payingfont">₹ {codstatus.currentBalance}</div>
         </div>
         
       </div>
       <div className="row mt-4 llongrow">
         <div className="col-6 rrowsheight codestart magerfont">
           {/* <div>Pay before</div>
           <div>Thu, Mar 18 2021</div> */}
         </div>
       </div>
       {/* <div className="row mt-4 llongrow">
         <div className="col-md-6 codestart rrowsheight">
           <div></div>
           <div>
           <Link to="/">
             <button type="button" className="btn btn-light btn-cancels">
             Cancel
             </button>
             </Link>
             </div>
             <div>
             <button type="button" className="btn btn-light btn-pay p-0">
             Pay
             </button>
             </div>
             </div>
            </div> */}
     </>
       </div>
       <div className="col-lg-5 col-sm-10 mt-5">
       <div className=" rounded p-2 bg-white colbox  ">
       
       <div className="row m-1">
         <div className="col-2 m-2 offset-1 imgdef">
           <img src={img4} alt="img" />
           <div className="text-rev pt-2">Liquid cash</div>

           <div className="text2 mt-4">₹ {''}{codstatus.cod_amount}  </div>
           <div className="text-danger green"></div>
         </div>

       
         <div className="col-1 offset-1 mt-2 imgdef">
           {/* <svg
             xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="16"
             fill="currentColor"
             className="bi bi-three-dots dropdown-btn cursorPointer"
             viewBox="0 0 16 16"
             >
             <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
           </svg> */}
           <div className="bottom">
           </div>
         </div>
       </div>
     </div>

       </div>
     </div>

     <div className="row mb-4 rounded px-3 bg-white">
       {/* header */}
       <div className="row m-0 List_mobile_title">
         <div className="font-weight-bold t-12 tFext-uppercase my-3 col-md-3">
           Date
         </div>
         <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
         Amount paid
         </div>
         <div className="font-weight-bold t-12 text-uppercase my-3 col-md-3">
         Status
         </div>
         <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">

         </div>
       </div>
       <div className="m-0 p-0 px-3 overflow-auto caterogyPageScroll">
         {
           codlist?.map(el => (
             <div className="row border rounded mb-2 ">
               <div className="t-12 my-3 col-md-3">
               <span className="fw-bold List_mobile_display">Date</span>
               {moment(el?.payment_date).format("D MMM, YYYY")}
               </div>
               <div className="t-12 my-3 col-md-3">
               <span className="fw-bold List_mobile_display">Amount paid</span>
                 {el?.amount}
               </div>
               <div className="t-12 my-3 col-md-3">
               <span className="fw-bold List_mobile_display">Status</span>
                 {el?.status}
               </div>
               <div className="t-12 d-flex justify-content-center  p-0 my-3 col-md-3">
                 {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16" >
                   <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                 </svg> */}
               </div>
             </div>

))
}
       </div>
     </div>
   </MainTemplate>
   )}
   
   </>

      )}
 

export default CODActive;
