import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import "../UserListing/UserListing.css";
import ReactPaginate from "react-paginate";
import { getApi, getTokenApi, putTokenApi } from "../../api_interface/apis";
import { FRANCHISE_CART, FRANCHISE_OFFLINE_CART, HIBEE_AUTH_URL, HIBEE_CART_URL, HIBEE_PRODUCT_URL, INVENTORY_LIST, PRODUCT_LIST, USERS } from "../../api_interface/apiURLs";
import { useDispatch, useSelector } from "react-redux";
import { addUserProducts, removeUserProduct } from "../../redux/reducers/userCartSlice";
import { debounce } from "../../utils/helperFunctin";
import imagenotavailable from '../../assets/images/not-available.jpg'

function ProductListing() {
  const dispatch = useDispatch()
  const { products, user } = useSelector(state => state?.userCartReducer)
  const [modal, setModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [itemOffset, setItemOffset] = useState(0);
  const [productList, setProductList] = useState([]);
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  // const pageCount = Math.ceil(productList.length / itemsPerPage);
  const [pageCount, setPageCount] = useState(1);
  // const currentProductList = productList.slice(itemOffset, endOffset);
  const [franchise, setFranchise] = useState([])
  const [normalOrderProductList, setNormalOrderProductList] = useState([]);
  const [superFastOrderProductList, setSuperFastOrderProductList] = useState([]);
  // const [orderType, setOrderType] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState("normal");
  
  const currentProductList =
    selectedOrderType === "normal"
      ? normalOrderProductList?.slice(itemOffset, itemOffset + itemsPerPage)
      : selectedOrderType === "superfast"
        ? superFastOrderProductList?.slice(itemOffset, itemOffset + itemsPerPage)
        : productList.slice(itemOffset, itemOffset + itemsPerPage);


  // console.log(currentProductList, 'jjj');

  // useEffect(() => {
  //   handleGetFranchiseProfile();
  // }, []);
  // const getCategorie = async (search) => {
  //   setLoader(true)
  //   const frenchiseId = franchise
  //   const res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_LIST + `/?franchiseId=${frenchiseId}&sort=${sort}&search=${search || query || ""}`);
  //   if (res.status == 200) {
  //     setProductList(res?.data?.data);
  //     setLoader(false)
  //   }
  // };
  // console.log(productList)
  const getCategories = async (search) => {
    setLoader(true);
    const res = await getTokenApi(HIBEE_AUTH_URL + USERS);
    // const hello = res?.data?.data?.frenchiseDetails
    const frenchiseId = res?.data?.data?.frenchiseDetails._id
    // const frenchiseId = franchise;
    const normalOrderRes = await getApi(
      HIBEE_PRODUCT_URL + PRODUCT_LIST + `/?franchiseId=${frenchiseId}&sort=${sort}&search=${search || query || ""}`
    );
    const superFastOrderRes = await getTokenApi(
      HIBEE_PRODUCT_URL + INVENTORY_LIST + `/?frenchiseId=${frenchiseId}&search=${query}`
    );
    // console.log(normalOrderRes, "superFastOrderRessuperFastOrderRes")
    if (normalOrderRes.status === 200 && superFastOrderRes.status === 200) {
      // Combine products from both responses
      const combinedProducts = [...normalOrderRes.data.data, ...superFastOrderRes.data.inventory];

      // Remove duplicates based on product IDs
      const uniqueProducts = [];
      const uniqueProductIds = new Set();

      combinedProducts.forEach(product => {
        if (!uniqueProductIds.has(product._id)) {
          uniqueProductIds.add(product._id);
          uniqueProducts.push(product);
        }
      });

      // Split the unique products into normal and superfast categories
      const normalProducts = uniqueProducts.filter(product => normalOrderRes?.data?.data.some(normalProduct => normalProduct._id === product._id));
      const superFastProducts = uniqueProducts.filter(product => superFastOrderRes?.data?.inventory.some(superFastProduct => superFastProduct._id === product._id));

      console.log(superFastProducts,"Superfast");

      setNormalOrderProductList(normalProducts);
      setSuperFastOrderProductList(superFastProducts)
      const totalUniqueProducts = normalProducts.length + superFastProducts.length;
      const newPageCount = Math.ceil(totalUniqueProducts / itemsPerPage);
      setPageCount(newPageCount);;
    }

    setLoader(false);
  };

  const handlePageClick = ({ selected }) => {
    const newOffset = selected * itemsPerPage;
    // console.log(newOffset, 'newoff');
    setItemOffset(newOffset);
  };

  const handlePreviousClick = () => {
    // Calculate the new offset and ensure it doesn't go below zero
    const newOffset = Math.max(0, itemOffset - itemsPerPage);
    setItemOffset(newOffset);
    getCategories("", newOffset);
  };
  // console.log(superFastOrderProductList)
  const syncUserCart = useMemo(() => debounce(async (products, user) => {
    if (user?._id && products?.length) {
      const data = products.map(el => ({ productId: el?._id, qty: el?.qty }))
      const res = await putTokenApi(HIBEE_CART_URL + FRANCHISE_CART + FRANCHISE_OFFLINE_CART + `?userId=${user?._id}`, { products: data })
    }
  }, 500), [])

  // console.log(currentProductList,"currentProductListcurrentProductListcurrentProductListcurrentProductList")

  useEffect(() => { syncUserCart(products, user) }, [products, user])

  const updateSort = e => {
    setSort(e.target.value)
  }
  useEffect(() => {
    getCategories("")
  }, [selectedOrderType, sort])
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await handleGetFranchiseProfile();
  //       getCategories("normal");
  //     } catch (error) {
  //       console.error("Error fetching franchise profile:", error);
  //     }
  //   };

  //   fetchData();
  // }, [sort]);

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getCategories(search)
  }, 500), [])



  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const handleGetFranchiseProfile = async () => {
    const res = await getTokenApi(HIBEE_AUTH_URL + USERS);
    // const hello = res?.data?.data?.frenchiseDetails
    const franchiseId = res?.data?.data?.frenchiseDetails._id
    setFranchise(franchiseId);
    // console.log(franchise, "franchisefranchise")

  };


  const handleAddUserProductDispatch = (el) => {
    dispatch(addUserProducts({ products: el, orderType: selectedOrderType }));
  }
  const handleRemoveUserProductDispatch = (el) => {
    dispatch(removeUserProduct(el))
  }


  const selectedOrderTypes = useSelector(state => state?.userCartReducer?.selectedOrderType);
  const [superfast, setSuperFast] = useState(selectedOrderTypes === 'superfast' ? true : null); // Initialize based on selectedOrderType

  useEffect(() => {
    // Update superfast whenever selectedOrderType changes
    setSuperFast(selectedOrderTypes === 'superfast');
  }, [selectedOrderTypes]);

  // console.log(superfast, '>>>>');

  return (
    <MainTemplate categoryName="Products" categoryList={["Franchise", "User Product Listing"]} >
      <div className="row mb-3 rounded p-2 bg-white">
        {/* <div className="col-sm-2 my-3">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div> */}
        <div className="col-sm-2 my-3 ">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-2 my-3 ">
          <select
            className="t-12 form-select"
            // aria-label="Default select example"
            onChange={(e) => {
              const orderType = e.target.value;
              setSelectedOrderType(orderType);
              getCategories(query);
            }}
            value={selectedOrderType}
            selected={selectedOrderType === "normal"}
          >
            <option value="normal" selected >Normal Order</option>
            <option value="superfast" >SuperFast Order</option>
          </select>
        </div>
        <div className="col-sm-3 my-3">
          <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="col-sm-5 my-3 d-flex justify-content-end">
          <Link to={user?._id ? `/user-cart/${user?._id}` : "/user-listing"}>
            <button type="button" className="t-12 btn btn-dark text-warning px-3"> {user?._id ? "Go to cart" : "Select User"} </button>
          </Link>
        </div>
      </div>

      {
        loader
          ?
          <div className="text-center mt-5" >
            <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
              Loading...
            </button>
          </div>
          :
          <div className="row rounded px-3 bg-white">
            {/* header */}
            <div className="row m-0 ">
              <div className="font-weight-bold t-12 tFext-uppercase my-3 col-md-1">
                ID
              </div>
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
                Photo
              </div>
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
                Product
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Price
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                Size
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1"></div>
            </div>

            <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
              {
                currentProductList?.map((el, index) => (
                  <div
                    className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                    key={el?._id}
                  >
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      <input type="checkbox" />
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      <div className="categoryImageDiv shadow-sm  rounded p-1">
                        {el?.images && el.images.length > 0 ? (
                          <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                        ) : (
                          <img src={el?.image ? el?.image : imagenotavailable} alt="loading" className="categoryImage h-100 rounded" />
                        )}
                      </div>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el?.product_name}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {'₹'} {el?.price}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el?.sizeList?.size}{el?.sizeList?.unit}
                    </div>
                    <div className="t-12 text-uppercase my-3 row justify-content-end col-md-4">
                      {el.sizeList.quantity === 0 ? (
                        <button className="addbtn text-end w-50 text-bg-dark" disabled>
                          Out of Stock
                        </button>
                      ) : (
                        products?.find((ele) => ele?._id === el?._id && ele?.qty > 0) ? (
                          <button
                            className="addbtn text-end w-50"
                            onClick={() => handleRemoveUserProductDispatch(el)}
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            className="addbtn text-end w-50"
                            onClick={() => handleAddUserProductDispatch(el)}
                          >
                            Add
                          </button>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>

            <div className="row m-2 d-flex justify-content-center align-items-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName="page-item mb-0"
                subContainerClassName="pages "
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>


          </div>
      }



    </MainTemplate>
  );
}

export default ProductListing;
