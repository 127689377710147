import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Login from "./pages/Login/Login";
import NewPassword from "./pages/NewPassword/NewPassword";
import Otp from "./pages/Otp/Otp";
import ScreenRouting from "./routes/ScreenRouting";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import store from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'


function App() {
  const persistor = persistStore(store);

  const appLoader = <div className="d-flex align-items-center justify-content-center position-relative" style={{width: "100vw", height: "100vh"}}>
    <div class="spinner-border position-absolute" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="spinner-grow spinner-grow-sm position-absolute" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  
  return (
    <Provider store={store}>
      <PersistGate loading={appLoader} persistor={persistor}>
        <ScreenRouting></ScreenRouting>
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;

