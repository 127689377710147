import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import "./FranchiseDetails.css";
import Edit from "../../../assets/images/Edit.png";
import Profile from "../../../assets/images/profile.png";
import MainTemplate from "../../../components/MainTemplate";

function FranchiseDetails() {
  return (
    <MainTemplate categoryName="Franchise #648439" categoryList={["Franchise", "Franchise", '#648439']}>
    <div className="container">
      <div className="row">
        <div className="rowhi"></div>
        <div className="col-md-5 row justify-content-center">
            <div className="col-6 mt-4" >
              <img src={Profile} className="imge mb-4" />

              <div className="box1 md-3 mb-3">
                <div className="size1">Name</div>
                <div className="size2 color1">Ram Nath Singh</div>
              </div>

              <div className="box2 md-3 mb-3">
                <div className="size1">Phone Number</div>
                <div className="size2 color1 underli">+91-987-675-6748</div>
              </div>

              <div className="box3 md-3 mb-3">
                <div className="size1">Email</div>
                <div className="size2 color1 underli">ramnaths1@gmail.com</div>
              </div>

              <div className="box4 md-3 mb-3">
                <div className="size1">Unique code</div>
                <div className="size2 color1">#648439</div>
              </div>
            </div>
        </div>

        <div className="col-md-1">
          <div className="horriLine"></div>
        </div>

        <div className="col-md-3 part2 md-3">
          <div className="box5 md-3 mt-4">
            <div className="size1">Address</div>
            <div className="addr">
              #152 Ram Sang Market, 123 Main Street, 21st Cross, 3rd Block,
              Pathana Road, opposite Rani mandir, Jalandhar, Punjab, 560078.
            </div>
          </div>

          <div className="box6 md-3 mb-3">
            <div className="size1">Society</div>
            <div className="size2 color2">Aashray Ferms</div>
          </div>

          <div className="box7 md-3 mb-3">
            <div className="size1">Reffered by</div>
            <div className="size2 color2">Radha Kishan from Sobha villas</div>
          </div>

          <div className="box8 md-3 mb-3">
            <div className="size1">Aadhar card number</div>
            <div className="size2 color2">5637-6374-7364</div>
          </div>

          <div className="box9 md-3 mb-3">
            <div className="size1">PAN</div>
            <div className="size2 color2">BUD76HEHE4</div>
          </div>
        </div>

        <div className="col-md-3 mb-3 row align-items-end">
          <div className="last col-8">
            <div className="col-9 md-3"></div>
            <img src={Edit} />
            <div className="Edits">
              Edit
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  );
}

export default FranchiseDetails;
