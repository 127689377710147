import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import CategoryPage from "../pages/superAdmin/CategoryPage/CategoryPage";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import Login from "../pages/Login/Login";
import NewPassword from "../pages/NewPassword/NewPassword";
import Otp from "../pages/Otp/Otp";
import NewFranchise from "../pages/superAdmin/NewFranchise/NewFranchise";
import CategoryDetail from "../pages/superAdmin/CategoryDetail/CategoryDetail";
import Orders from "../pages/Orders/Orders";
import OrderDetails from "../pages/Orders/OrderDetails";
import Inventory from "../pages/superAdmin/Inventory/Inventory";
import InventoryProductAddForm from "../pages/superAdmin/InventoryProductAddForm/InventoryProductAddForm";
import HomepageModifier from "../pages/superAdmin/HomepageModifier/HomepageModifier";
import HomepageModifierCategory from "../pages/superAdmin/HomepageModifier/HomepageModifierCategory";
import HomepageModifierCategoryInternal from "../pages/superAdmin/HomepageModifier/HomepageModifierCategoryInternal";
import HomepageModifierProducts from "../pages/superAdmin/HomepageModifier/HomepageModifierProducts";
import HomepageModifierAdvertisement from "../pages/superAdmin/HomepageModifier/HomepageModifierAdvertisement";
import OfferSection from "../pages/superAdmin/OfferSection/OfferSection";
import Notification from "../pages/superAdmin/Notification/Notification";
import ProductPrice from "../pages/superAdmin/Product-Price/ProductPrice";
import FranchiseDetails from "../pages/superAdmin/FranchiseDetails/FranchiseDetails";
import AdminForm from "../pages/superAdmin/AdminForm/AdminForm";
import AdminDetail from "../pages/superAdmin/AdminDetail/AdminDetail";
import FranchiseRequestDetails from "../pages/FranchiseRequestDetails/FranchiseRequestDetails";
import NewOfferForm from "../pages/NewOfferForm/NewOfferForm";
import FeedbackInternal from "../pages/FeedbackInternal/FeedbackInternal";
import { useSelector } from "react-redux";
import ProductListing from "../pages/ProductListing/ProductListing";
import InventoryProductListing from "../pages/ProductListing/InventoryProductListing";
import UserListing from "../pages/UserListing/UserListing";
import Inventoryscreen from "../pages/Inventory/Inventoryscreen";
import CODActive from "../pages/CODActive/CODActive";
import ProfileEdit from "../pages/ProfileEdit/ProfileEdit";
import FranchiseProfile from "../pages/FranchiseProfile/FranchiseProfile";
import Referral from "../pages/Referral/Referral";
import InventoryCart from "../pages/CartPage/InventoryCart";
import UserCart from "../pages/CartPage/UserCart";
import DataOfUsers from "../pages/DataOfUsers/DataOfUsers";
import Revenue from "../pages/superAdmin/Revenue/Revenue";
import ProductList from "../pages/ProductListing/ProductList"
import OrderPdf from "../pages/Orders/OrdersPdf";
import UserInfo from "../pages/UserListing/UserInfo";

function ScreenRouting() {
  const user = useSelector((state) => state.userReducer);

  return !user?._id ? (
    <Router>
      <Routes>
        {/* Onboarding */}
        <Route path="/Otp" element={<Otp />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="/newPassword" element={<NewPassword />}></Route>
        <Route path="*" element={<Navigate to="/" />}></Route>
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        {/* Super Admin Screens */}
        <Route path="/" element={<Revenue />}></Route>
        <Route path="/categories" element={<CategoryPage />}></Route>
        <Route path="/categories/:categoryId" element={<CategoryDetail />} ></Route>
        <Route path="/franchise" element={<NewFranchise />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/order-details/:orderId" element={<OrderDetails />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory-product-add" element={<InventoryProductAddForm />} />
        <Route path="/homepage-modifier" element={<HomepageModifier />} />
        <Route path="/homepage-modifier-category" element={<HomepageModifierCategory />} />
        <Route path="/homepage-modifier-category-internal" element={<HomepageModifierCategoryInternal />} />
        <Route path="/homepage-modifier-products" element={<HomepageModifierProducts />} />
        <Route path="/homepage-modifier-advertisement" element={<HomepageModifierAdvertisement />} />
        {/* <Route path="/offer-section" element={<OfferSection />} /> */}
        <Route path="/notification" element={<Notification />} />
        <Route path="/product-price" element={<ProductPrice />} />
        <Route path="/franchise-details" element={<FranchiseDetails />} />
        <Route path="/admin-form" element={<AdminForm />} />
        <Route path="/admin-detail" element={<AdminDetail />} />
        <Route path="/franchise-request-details" element={<FranchiseRequestDetails />} ></Route>
        <Route path="/new-offer-form" element={<NewOfferForm />}></Route>
        <Route path="/user-listing" element={<UserListing />}></Route>
        <Route path="/fedback-internal" element={<FeedbackInternal />} />
        <Route path="/inventoryscreen" element={<Inventoryscreen />} />
        <Route path="/product-listing" element={<ProductListing />}></Route>
        <Route path="/inventory-product-listing" element={<InventoryProductListing />} ></Route>
        <Route path="/inventory-cart" element={<InventoryCart />} />
        <Route path="/user-cart/:userId" element={<UserCart />} />
        <Route path="/dataofusers" element={<DataOfUsers />} />
        <Route path="/cod-active" element={<CODActive />} />
        <Route path="/franchise-profile" element={<FranchiseProfile />} />
        <Route path="/profile-edit" element={<ProfileEdit />} />
        <Route path="/referal" element={<Referral />} />
        <Route path="/revenue" element={<Revenue />} />
        <Route path="/inventory-product-listing/:subCategoryId" element={<InventoryProductListing/>} />
        <Route path = "userInfo/:_id" element ={<UserInfo/>}/>
      </Routes>
    </Router>
  );
}

export default ScreenRouting;
